import { appCreateStyles } from '@core/theme/utils/create-styles';
import { Theme } from '@mui/material';
import { _var, CSS_VARIABLES, Layout } from '@shared/utils/layout';

import ArrowRight from './img/arrow-right.svg';
import CopyIcon from './img/copy-button.svg';

export function styles(theme: Theme) {
  return appCreateStyles({
    root: {
      width: '100%',
      height: `fit-content`,
      display: 'flex',
      flexDirection: 'column',
      alignSelf: 'start',
    },
    title: {
      color: '#FFF',
      textAlign: 'center',
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: '22px',
      letterSpacing: '0.3px',
    },
    section: {
      marginTop: '20px',
      '& ul': {
        paddingLeft: '0px',
      },
    },
    sectionTitle: {
      color: '#778',
      fontSize: '14px',
      fontWeight: 600,
      letterSpacing: '0.2px',
      paddingLeft: '10px',
      marginBottom: '16px',
      [theme.breakpoints.up(Layout.laptop)]: {
        color: '#A4A8AB',
      },
    },
    logoutButtonBlock: {
      marginTop: '20px',
    },
    logoutButton: {
      cursor: 'pointer',
      borderRadius: '12px 12px 12px 12px',
      backgroundColor: '#21203B',
      color: '#106EED',
      fontSize: '15px',
      fontWeight: 600,
      letterSpacing: '0.2px',
      height: '52px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    version: {
      color: '#79818F',
      fontSize: '14px',
      fontWeight: 600,
      letterSpacing: '0.028px',
      paddingLeft: '10px',
      marginTop: '20px',
    },
    menu: {
      marginTop: '16px',
    },
    userInfo: {
      height: '96px !important',
      display: 'flex',
      alignItems: 'center',
    },
    menuItem: {
      backgroundColor: '#21203B',
      height: '48px',
      width: '100%',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0px 16px',

      '& + &': {
        borderTop: '0.5px solid rgba(255, 255, 255, 0.10)',
      },
      '&:first-of-type': {
        borderRadius: '12px 12px 0px 0px',
      },
      '&:last-of-type': {
        borderRadius: '0px 0px 12px 12px',
      },
      [theme.breakpoints.up(Layout.laptop)]: {
        background: '#412D68',
      },
    },
    withArrow: {
      cursor: 'pointer',
      backgroundImage: `url(${ArrowRight})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right 16px center',
      paddingRight: '36px !important',
    },
    withCopy: {
      backgroundImage: `url(${ArrowRight})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right 16px center',
    },
    phoneNumber: {
      color: '#FFF',
      fontSize: '21px',
      fontWeight: 600,
      lineHeight: '22px',
      letterSpacing: '0.3px',
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    image: {
      width: '67px',
      height: '67px',
    },
    icon: {
      position: 'absolute',
      left: '8px',
    },
    userName: {
      color: _var(CSS_VARIABLES.colorAdditionalWhite),
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '160%',
      letterSpacing: '0.2px',
      textOverflow: 'ellipsis',
      wordBreak: 'keep-all',
      overflow: 'hidden',
      textWrap: 'nowrap',

      '&::after': {
        content: ' ',
        display: 'block',
        width: 0,
        height: 0,
        lineHeight: 0,
      },
    },
    phoneBlock: {
      width: 'calc(100% - 20px)',
    },
    phoneLabel: {
      color: 'rgba(247, 249, 252, 0.40)',
      fontSize: '13px',
      fontWeight: 600,
      letterSpacing: '0.2px',
      textOverflow: 'ellipsis',
      '&::after': {
        content: ' ',
        display: 'block',
        width: 0,
        height: 0,
        lineHeight: 0,
      },
    },
    nameBlock: {
      overflow: 'hidden',
      marginLeft: '74px',
    },
    fieldName: {
      overflow: 'hidden',
      color: '#fff',
      fontFeatureSettings: "'clig' off, 'liga' off",
      textOverflow: 'ellipsis',
      wordBreak: 'keep-all',
      whiteSpace: 'nowrap',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '22px',
      letterSpacing: '0.3px',
      minWidth: 'fit-content',
    },
    fieldButton: {
      overflow: 'hidden',
      color: '#106EED',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontSize: '14px',
      fontWeight: 600,
      letterSpacing: '0.2px',
      wordBreak: 'keep-all',
      width: '208px',
      'a&': {
        width: '100%',
        textDecoration: 'none',
      },
    },
    fieldIcon: {
      marginRight: '9px',
      display: 'flex',
      alignItems: 'center',
    },
    fieldValue: {
      color: '#778',
      fontSize: '14px',
      fontWeight: 600,
      letterSpacing: '0.2px',
      maxWidth: '100%',
      textWrap: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      wordBreak: 'keep-all',
      paddingLeft: '10px',
      [theme.breakpoints.up(Layout.laptop)]: {
        color: '#A4A8AB',
      },
    },
    nameInput: {},
    flex: {
      display: 'flex',
    },
    copyButton: {
      cursor: 'pointer',
      backgroundImage: `url(${CopyIcon})`,
      backgroundRepeat: 'no-repeat',
      width: '50px',
      height: '30px',
      backgroundPosition: 'right center',
    },
    status: {
      fontWeight: 500,
    },
    underReview: {
      color: '#D9C61D',
      fontWeight: 500,
    },
    declined: {
      color: '#E31313',
      fontWeight: 500,
    },
    verified: {
      color: '#1DCE5C',
      fontWeight: 500,
    },
    invisibleContent: {
      height: '100%',
    },
  });
}
