import { appInject } from '@core/di/utils';
import { appMakeObservable, appObservable } from '@core/state-management/utils';
import { DI_TOKENS } from '@shared/constants/di';
import { UserDetailsModel } from '@shared/models/users/details-model';
import { IUsersService } from '@shared/types/users-service';

export class HelpCenterViewModel {
  private usersService = appInject<IUsersService>(DI_TOKENS.usersService);
  private _state = {
    userData: null as UserDetailsModel | null,
  };

  constructor() {
    appMakeObservable(this, {
      _state: appObservable,
    });
  }

  get name() {
    return this._state.userData?.asJson.systemName || '';
  }

  async fetchUserInfo() {
    this._state.userData = await this.usersService.getMyUserDetails();
  }
}
