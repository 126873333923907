import React from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';

import Documents from '../documents/documents';

import { styles } from './documents-mobile-container.styles';

export interface DocumentsMobileContainerProps extends AppWithStyles<typeof styles> {}

const DocumentsMobileContainerComponent: React.FC<DocumentsMobileContainerProps> = ({
  classes,
}) => {
  return (
    <div className={classes.root}>
      <Documents />
    </div>
  );
};

export default appWithStyles(styles)(DocumentsMobileContainerComponent);
