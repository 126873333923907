import { appInject } from '@core/di/utils';
import { DI_TOKENS } from '@shared/constants/di';
import { UserDetailsModel } from '@shared/models/users/details-model';
import { IUsersService } from '@shared/types/users-service';

export class PersonalInfoViewModel {
  private usersService = appInject<IUsersService>(DI_TOKENS.usersService);
  private _me = {
    data: null as UserDetailsModel | null,
  };

  get me() {
    return this._me.data;
  }

  async fetchMyUserDetails() {
    this._me.data = await this.usersService.getMyUserDetails();
  }

  async changeAlterName(alterName: string) {
    this._me.data = await this.usersService.updateAlterName(alterName);
  }

  isValidAlterName(alterName: string) {
    return alterName.length > 2 && alterName.length < 31;
  }
}
