import { appCreateStyles } from '@core/theme/utils/create-styles';
import { Theme } from '@mui/material';

export function styles(theme: Theme) {
  return appCreateStyles({
    content: {
      padding: '20px',
      width: '367px',
      height: 'fit-content',
      borderRadius: '12px',
    },
    invisibleContent: {
      height: '100%',
    },
  });
}
