import React, { useMemo, useState } from 'react';

import { appObserver } from '@core/state-management/utils';
import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { Theme, useMediaQuery } from '@mui/material';
import { Loading } from '@shared/components/loading';
import { LogoutDialog } from '@shared/components/new-design/logout-dialog';
import SubmitButton from '@shared/components/new-design/submit-button';
import { useNavigate } from '@shared/components/router';
import { ROUTES } from '@shared/constants/routes';
import { UserStatus } from '@shared/models/users/user-status';
import { transformToBaseCurrency } from '@shared/utils/currency-pipe';
import { Layout } from '@shared/utils/layout';
import classNames from 'classnames';
import clipboardCopy from 'clipboard-copy';
import { useQuery } from 'react-query';

import AccountSecurityIcon from './img/account-security.svg';
import AccountStatusIcon from './img/account-status.svg';
import DeletionRequestIcon from './img/deletion-request.svg';
import DocumentsIcon from './img/documents.svg';
import HelpCenterIcon from './img/help-center.svg';
import NotificationsIcon from './img/notifications.svg';
import PaymentMetodsIcon from './img/payment-methods.svg';
import SystemCurrencyIcon from './img/system-currency.svg';
import YouOwnIcon from './img/you-own.svg';
import { MobileSettingsViewModel } from './settings.vm';

import { styles } from './settings.styles';

export interface SettingsProps extends AppWithStyles<typeof styles> {}

const Settings: React.FC<SettingsProps> = appObserver(({ classes }) => {
  const $vm = useMemo(() => new MobileSettingsViewModel(), []);
  const [isOpenedLogoutDialog, setIsOpenedLogoutDialog] = useState<boolean>(false);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(Layout.laptop));
  const navigate = useNavigate();

  const navigateToPersonalInfo = () => {
    isMobile
      ? navigate(ROUTES.mobilePrivate.settings.personalInfo)
      : navigate(ROUTES.desktopPrivate.settings.personalInfo);
  };

  const navigateToCertificates = () => {
    isMobile
      ? navigate(ROUTES.mobilePrivate.settings.certificates)
      : navigate(ROUTES.desktopPrivate.settings.certificates);
  };

  const navigateToSecurity = () => {
    isMobile
      ? navigate(ROUTES.mobilePrivate.settings.security.root)
      : navigate(ROUTES.desktopPrivate.settings.security.root);
  };

  const navigateToCurrency = () => {
    isMobile
      ? navigate(ROUTES.mobilePrivate.settings.currency)
      : navigate(ROUTES.desktopPrivate.settings.currency);
  };

  const navigateToCards = () => {
    isMobile
      ? navigate(ROUTES.mobilePrivate.settings.cards)
      : navigate(ROUTES.desktopPrivate.settings.cards);
  };

  const navigateToNotifications = () => {
    isMobile
      ? navigate(ROUTES.mobilePrivate.settings.notifications)
      : navigate(ROUTES.desktopPrivate.settings.notifications);
  };

  const navigateToDocuments = () => {
    isMobile
      ? navigate(ROUTES.mobilePrivate.settings.documents)
      : navigate(ROUTES.desktopPrivate.settings.documents);
  };

  const navigateToHelpCenter = () => {
    isMobile
      ? navigate(ROUTES.mobilePrivate.settings.helpCenter.root)
      : navigate(ROUTES.desktopPrivate.settings.helpCenter.root);
  };

  const navigateToDeleteAccount = () => {
    isMobile
      ? navigate(ROUTES.mobilePrivate.settings.deleteAccount)
      : navigate(ROUTES.desktopPrivate.settings.deleteAccount);
  };

  const handleLogout = () => {
    $vm.handleLogout();
    navigate(ROUTES.public.login());
  };

  const fetchMyUserDetailsQuery = useQuery(['fetch-my-user-data'], () => $vm.fetchMyUserDetails(), {
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false,
  });

  const fetchUserInfoQuery = useQuery(['fetch-user-info'], () => $vm.fetchUserInfo(), {
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false,
  });

  const fetchMyBlockchainWalletQuery = useQuery(
    ['fetch-my-blockchain-wallet'],
    () => $vm.fetchMyBlockchainWallet(),
    {
      retry: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
    },
  );

  const fetchWalletsQuery = useQuery(['fetch-wallets'], () => $vm.fetchWallets(), {
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false,
  });

  const handleCopy = async (value: string) => {
    await clipboardCopy(value);
    $vm.showMessage(t`Information was copied in buffer`);
  };

  if (
    fetchMyUserDetailsQuery.isFetching ||
    fetchWalletsQuery.isFetching ||
    fetchMyBlockchainWalletQuery.isFetching ||
    fetchUserInfoQuery.isFetching
  ) {
    return (
      <div className={classNames(classes.root, classes.invisibleContent, 'invisible-content')}>
        <Loading size={70} />
      </div>
    );
  }

  let userStatusStyle;
  let userStatusText = $vm.me?.asJson.userStatus;
  switch ($vm.me?.asJson.userStatus) {
    case UserStatus.NotVerified:
      userStatusStyle = classes.status;
      userStatusText = t`Not verified`;
      break;
    case UserStatus.UnderReview:
      userStatusStyle = classes.underReview;
      userStatusText = t`Under review`;
      break;
    case UserStatus.Declined:
      userStatusStyle = classes.declined;
      userStatusText = t`Declined`;
      break;
    case UserStatus.Verified:
      userStatusStyle = classes.verified;
      userStatusText = t`Verified`;
      break;
    default:
      userStatusStyle = classes.status;
  }

  let userIcon = <></>;

  if ($vm.me?.asJson.avatarUrl) {
    userIcon = (
      <div className={classes.icon}>
        <div
          style={{
            width: '67px',
            height: '67px',
            borderRadius: '50%',
            overflow: 'hidden',
            backgroundColor: `${$vm.me?.asJson.systemColor}`,
          }}
        >
          <img className={classes.image} src={$vm.me?.asJson.avatarUrl} />
        </div>
      </div>
    );
  } else {
    userIcon = (
      <div className={classes.icon}>
        <div
          style={{
            width: '67px',
            height: '67px',
            borderRadius: '50%',
            backgroundColor: `${$vm.me?.asJson.systemColor}`,
          }}
        >
          <div className={classes.phoneNumber}>{$vm.me?.asJson.initials}</div>
        </div>
      </div>
    );
  }

  const smartCoinWallet = $vm.getSmartCoinWallet();
  return (
    <div className={classes.root}>
      <div className={classes.title}>{t`Settings`}</div>
      <div className={classes.section}>
        <ul className={classes.menu}>
          <li
            className={classNames(classes.menuItem, classes.userInfo, classes.withArrow)}
            onClick={navigateToPersonalInfo}
          >
            {userIcon}
            <div className={classes.nameBlock}>
              <div className={classes.userName}>
                <span>{$vm.me?.asJson.systemName}</span>
              </div>
              <div className={classes.nameInput}>
                <div className={classes.phoneBlock}>
                  <div className={classes.phoneLabel}>{$vm.me?.asJson.phone}</div>
                </div>
              </div>
            </div>
          </li>
          <li className={classes.menuItem}>
            <div className={classes.flex}>
              <div className={classes.fieldIcon}>
                <img src={String(YouOwnIcon)} />
              </div>
              <div className={classes.fieldName}>{t`You own`}</div>
            </div>
            <div
              className={classes.fieldValue}
            >{`${smartCoinWallet?.asJson.balance} ${smartCoinWallet?.asJson.currency}`}</div>
          </li>
          {(smartCoinWallet?.asJson.balance || 0) > 0 ? (
            <li
              className={classNames(classes.menuItem, classes.withArrow)}
              onClick={navigateToCertificates}
            >
              <div className={classes.fieldButton}>{t`Certificate of ownership`}</div>
            </li>
          ) : (
            <></>
          )}
        </ul>
      </div>
      <div className={classes.section}>
        <div className={classes.sectionTitle}>{t`Account details`}</div>
        <ul className={classes.menu}>
          <li className={classes.menuItem}>
            <div className={classes.fieldName}>{t`User ID`}</div>
            <div className={classes.fieldValue}>{$vm.me?.asJson.id}</div>
            <div
              className={classes.copyButton}
              onClick={() => handleCopy($vm.me?.asJson.id || '')}
            ></div>
          </li>
          <li className={classes.menuItem}>
            <div className={classes.fieldName}>{t`Email`}</div>
            <div className={classes.fieldValue}>{$vm.me?.asJson.email}</div>
            <div
              className={classes.copyButton}
              onClick={() => handleCopy($vm.me?.asJson.email || '')}
            ></div>
          </li>
          <li className={classes.menuItem}>
            <div className={classes.fieldName}>{t`Account Public Key`}</div>
            <div className={classes.fieldValue}>{$vm.myBlockchainWallet?.asJson.publicKey}</div>
            <div
              className={classes.copyButton}
              onClick={() => handleCopy($vm.myBlockchainWallet?.asJson.publicKey || '')}
            ></div>
          </li>
          <li className={classNames(classes.menuItem, classes.withArrow)}>
            <a
              className={classes.fieldButton}
              target="_blank"
              href={$vm.blockchainWalletLink}
            >{t`Transactions in Blockchain`}</a>
          </li>
        </ul>
      </div>
      <div className={classes.section}>
        <div className={classes.sectionTitle}>{t`Profile settings`}</div>
        <ul className={classes.menu}>
          <li className={classNames(classes.menuItem, classes.withArrow)}>
            <div className={classes.flex}>
              <div className={classes.fieldIcon}>
                <img src={String(AccountStatusIcon)} />
              </div>
              <div className={classes.fieldName}>{t`Account status`}</div>
            </div>
            <div className={classNames(classes.fieldValue, userStatusStyle)}>{userStatusText}</div>
          </li>
          <li
            className={classNames(classes.menuItem, classes.withArrow)}
            onClick={navigateToSecurity}
          >
            <div className={classes.flex}>
              <div className={classes.fieldIcon}>
                <img src={String(AccountSecurityIcon)} />
              </div>
              <div className={classes.fieldName}>{t`Account security`}</div>
            </div>
            <div className={classes.fieldValue}>
              {$vm.isUserPhoneVerified ? (
                <span className={classes.verified}>{t`2FA`}</span>
              ) : (
                <span>{t`No 2FA`}</span>
              )}
            </div>
          </li>
          <li
            className={classNames(classes.menuItem, classes.withArrow)}
            onClick={navigateToCurrency}
          >
            <div className={classes.flex}>
              <div className={classes.fieldIcon}>
                <img src={String(SystemCurrencyIcon)} />
              </div>
              <div className={classes.fieldName}>{t`System currency`}</div>
            </div>
            <div className={classes.fieldValue}>
              {transformToBaseCurrency($vm.getActiveCurrency())}
            </div>
          </li>
          <li className={classNames(classes.menuItem, classes.withArrow)} onClick={navigateToCards}>
            <div className={classes.flex}>
              <div className={classes.fieldIcon}>
                <img src={String(PaymentMetodsIcon)} />
              </div>
              <div className={classes.fieldName}>{t`Payment methods`}</div>
            </div>
          </li>
          <li
            className={classNames(classes.menuItem, classes.withArrow)}
            onClick={navigateToNotifications}
          >
            <div className={classes.flex}>
              <div className={classes.fieldIcon}>
                <img src={String(NotificationsIcon)} />
              </div>
              <div className={classes.fieldName}>{t`Notifications`}</div>
            </div>
          </li>
          <li
            className={classNames(classes.menuItem, classes.withArrow)}
            onClick={navigateToDocuments}
          >
            <div className={classes.flex}>
              <div className={classes.fieldIcon}>
                <img src={String(DocumentsIcon)} />
              </div>
              <div className={classes.fieldName}>{t`Legal documents`}</div>
            </div>
          </li>
          <li
            className={classNames(classes.menuItem, classes.withArrow)}
            onClick={navigateToDeleteAccount}
          >
            <div className={classes.flex}>
              <div className={classes.fieldIcon}>
                <img src={String(DeletionRequestIcon)} />
              </div>
              <div className={classes.fieldName}>{t`Block/Delete account`}</div>
            </div>
          </li>
          <li
            className={classNames(classes.menuItem, classes.withArrow)}
            onClick={navigateToHelpCenter}
          >
            <div className={classes.flex}>
              <div className={classes.fieldIcon}>
                <img src={String(HelpCenterIcon)} />
              </div>
              <div className={classes.fieldName}>{t`Help Center`}</div>
            </div>
          </li>
        </ul>
      </div>
      {isMobile ? (
        <div className={classes.logoutButtonBlock}>
          <div
            className={classes.logoutButton}
            onClick={() => setIsOpenedLogoutDialog(true)}
          >{t`Log Out`}</div>
        </div>
      ) : (
        <div className={classes.logoutButtonBlock}>
          <SubmitButton onSubmit={() => setIsOpenedLogoutDialog(true)} label={t`Log Out`} />
        </div>
      )}
      <div className={classes.version}>
        {t`Wealthstack version`} {$vm.version}
      </div>
      <LogoutDialog
        isOpen={isOpenedLogoutDialog}
        onClose={() => setIsOpenedLogoutDialog(false)}
        onLogout={handleLogout}
      />
    </div>
  );
});

export default appWithStyles(styles)(Settings);
