import React, { useMemo } from 'react';

import { appObserver } from '@core/state-management/utils';
import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { IssueViewModel } from '@modules/new-private/settings/components/issue/issue.vm';
import { TextField, Theme, useMediaQuery } from '@mui/material';
import { BackButton } from '@shared/components/new-design/back-button/back-button';
import SubmitButton from '@shared/components/new-design/submit-button/submit-button';
import { useNavigate } from '@shared/components/router';
import { ROUTES } from '@shared/constants/routes';
import { IssueType } from '@shared/enums/issue-type.enum';
import { Layout } from '@shared/utils/layout';
import classNames from 'classnames';
import { useMutation } from 'react-query';

import { styles } from './issue.styles';

export interface IssueProps extends AppWithStyles<typeof styles> {
  title: string;
  type: IssueType;
}

const IssueComponent: React.FC<IssueProps> = appObserver(({ classes, title, type }) => {
  const $vm = useMemo(() => new IssueViewModel(type), []);

  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(Layout.tablet));

  const navigateToHelpCenter = () => {
    isMobile
      ? navigate(ROUTES.mobilePrivate.settings.helpCenter.root)
      : navigate(ROUTES.desktopPrivate.settings.helpCenter.root);
  };

  const sendMessageMutation = useMutation(
    () =>
      $vm.sendMessage({
        subject: $vm.getSubjectByIssueType(type),
        systemVersion: $vm.version,
        device: $vm.browserInfo,
        transactionId: $vm.transactionId || undefined,
        body: $vm.body,
      }),
    {
      onSuccess: () => {
        $vm.refreshStorage();
        navigateToHelpCenter();
      },
    },
  );

  return (
    <div
      className={classNames(classes.root, {
        [classes.transaction]: type === IssueType.TRANSACTION_ISSUE,
        [classes.nonTransaction]: type !== IssueType.TRANSACTION_ISSUE,
      })}
    >
      <div className={classes.issueBlock}>
        <div className={classes.issue}>
          <div>
            <div className={classes.backButton}>
              <BackButton onClick={navigateToHelpCenter} />
            </div>
            <div className={classes.title}>{title}</div>
            <div className={classes.section}>
              <ul className={classes.menu}>
                <li className={classes.menuItem}>
                  <div className={classes.flex}>
                    <div className={classes.fieldName}>{t`To`}:</div>
                  </div>
                  <div className={classes.fieldValue}>{$vm.supportEmail}</div>
                </li>
                <li className={classes.menuItem}>
                  <div className={classes.flex}>
                    <div className={classes.fieldName}>{t`Cc/Bcc, From`}:</div>
                  </div>
                  <div className={classes.fieldValue}>{$vm.email}</div>
                </li>
                <li className={classes.menuItem}>
                  <div className={classes.flex}>
                    <div className={classes.fieldName}>{t`Subject`}:</div>
                  </div>
                  <div className={classes.fieldValue}>{title}</div>
                </li>
              </ul>
            </div>
            <div className={classes.info}>
              <div className={classes.infoField}>
                <div className={classes.infoFieldName}>{t`Version`}:</div>
                <div className={classes.infoFieldValue}>{$vm.version}</div>
              </div>
              <div className={classes.infoField}>
                <div className={classes.infoFieldName}>{t`Browser`}:</div>
                <div className={classes.infoFieldValue}>{$vm.browserInfo}</div>
              </div>
              <div className={classes.infoField}>
                <div className={classes.infoFieldName}>{t`User ID`}:</div>
                <div className={classes.infoFieldValue}>{$vm.userId}</div>
              </div>
            </div>
            {type === IssueType.TRANSACTION_ISSUE ? (
              <div className={classes.inputBlock}>
                <TextField
                  value={$vm.transactionId}
                  className={classes.input}
                  placeholder={t`Past transaction ID here (mandatory)`}
                  onChange={(e) => $vm.handleChangeTransactionId(e.target.value)}
                ></TextField>
              </div>
            ) : (
              <></>
            )}
            <div className={classes.inputBlock}>
              <TextField
                multiline={true}
                value={$vm.body}
                className={classes.input}
                placeholder={t`Describe an issue here (min 20, max 250 letters)`}
                onChange={(e) => $vm.handleChangeBody(e.target.value)}
              ></TextField>
            </div>
            <div className={classes.saveButtonBlock}>
              <SubmitButton
                label={t`Submit request`}
                disabled={$vm.transactionIdError || $vm.bodyError}
                isLoading={sendMessageMutation.isLoading}
                onSubmit={() => sendMessageMutation.mutate()}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default appWithStyles(styles)(IssueComponent);
