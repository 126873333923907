import { appCreateStyles } from '@core/theme/utils/create-styles';
import { Theme } from '@mui/material';
import { _var, CSS_VARIABLES, Layout } from '@shared/utils/layout';

export function styles(theme: Theme) {
  return appCreateStyles({
    root: {
      width: '100%',
      [theme.breakpoints.up(Layout.laptop)]: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',

        '& .MuiButton-root': {
          background: '#412D68',
          border: 'none !important',
        },
      },
    },
    documentsBlock: {
      [theme.breakpoints.up(Layout.laptop)]: {
        borderRadius: '12px',
        background: 'transparent',
      },
      [theme.breakpoints.down(Layout.tablet)]: {
        width: '100%',
        height: '100%',
        borderRadius: '12px',
      },
    },
    documents: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    title: {
      color: _var(CSS_VARIABLES.colorAdditionalWhite),
      textAlign: 'center',
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: '165%',
      letterSpacing: '0.4px',
      marginBottom: '27px',
      [theme.breakpoints.up(Layout.laptop)]: {
        color: '#fff',
        marginBottom: '10px',
      },
    },
    description: {
      padding: '10px',
      color: '#778',
      fontSize: '13px',
      fontWeight: 500,
      lineHeight: '17px',
      [theme.breakpoints.up(Layout.laptop)]: {
        color: '#A4A8AB',
      },
    },
    fields: {
      marginTop: '10px',
    },
    field: {
      cursor: 'pointer',
      '& + &': {
        marginTop: '10px',
      },
    },
    backButton: {
      position: 'absolute',
      left: '20px',
      top: '20px',
      [theme.breakpoints.up(Layout.laptop)]: {
        display: 'none',
      },
    },
  });
}
