import { appInject } from '@core/di/utils';
import { DI_TOKENS } from '@shared/constants/di';
import { IssueType } from '@shared/enums/issue-type.enum';
import { IAuthService } from '@shared/types/auth-service';

export class IssueRepository {
  private authService = appInject<IAuthService>(DI_TOKENS.authService);
  private storageFieldName = 'issue';
  private type: IssueType;

  setType(type: IssueType) {
    this.type = type;
    return this;
  }

  saveToStorage(data: { transactionId: string; body: string }): void {
    if (!this.authService.userId || !this.type) return;
    this._saveToStorage(data);
  }

  getFromStorage() {
    const key = this.generateKey();
    return this.jsonToData(localStorage.getItem(key) || '');
  }

  removeFromStorage() {
    if (!this.authService.userId || !this.type) return;
    const key = this.generateKey();
    localStorage.removeItem(key);
  }

  private generateKey(): string {
    return `${this.storageFieldName}:${this.authService.userId}:${this.type}`;
  }

  private jsonToData(json: string): { transactionId: string; body: string } {
    try {
      return JSON.parse(json);
    } catch (ex) {}
    return {
      transactionId: '',
      body: '',
    };
  }

  private _saveToStorage(data: { transactionId: string; body: string }) {
    const key = this.generateKey();
    localStorage.setItem(key, JSON.stringify(data));
  }
}
