import React, { FC } from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { DesktopContetLayout } from '@modules/new-private/layout/desktop-content-layout';
import { ROUTES } from '@shared/constants/routes';

// eslint-disable-next-line import/namespace,import/default
import Documents from '../documents/documents';

import { styles } from './documents-desktop-container.styles';

export interface DocumentsDesktopContainerProps extends AppWithStyles<typeof styles> {}

const DocumentsDesktopContainerComponent: FC<DocumentsDesktopContainerProps> = ({ classes }) => {
  const routes = [
    { label: t`Settings`, path: ROUTES.desktopPrivate.settings.root },
    { label: t`Documents`, path: ROUTES.desktopPrivate.settings.documents, disabled: true },
  ];

  return (
    <DesktopContetLayout links={routes}>
      <div className={classes.content}>
        <Documents />
      </div>
    </DesktopContetLayout>
  );
};
export default appWithStyles(styles)(DocumentsDesktopContainerComponent);
