import { appInject } from '@core/di/utils';
import { appMakeObservable, appObservable } from '@core/state-management/utils';
import { DI_TOKENS } from '@shared/constants/di';
import { UserDetailsModel } from '@shared/models/users/details-model';
import { IAuthService } from '@shared/types/auth-service';
import { IUsersService } from '@shared/types/users-service';

export class DeleteAccountViewModel {
  private authService = appInject<IAuthService>(DI_TOKENS.authService);
  private usersService = appInject<IUsersService>(DI_TOKENS.usersService);

  private _state = {
    canBeDeleted: false as boolean,
    userData: null as UserDetailsModel | null,
  };

  get name() {
    return this._state.userData?.asJson.systemName || '';
  }

  get canBeDeleted() {
    return this._state.canBeDeleted;
  }

  constructor() {
    appMakeObservable(this, {
      _state: appObservable,
    });
  }

  async fetchUserInfo() {
    this._state.userData = await this.usersService.getMyUserDetails();
  }

  async canSendDeletionRequest() {
    this._state.canBeDeleted = await this.usersService.canSendDeletionRequest();
  }

  sendDeletionRequest() {
    return this.usersService.sendDeletionRequest();
  }

  logout() {
    this.authService.signOut();
  }
}
