import { appCreateStyles } from '@core/theme/utils/create-styles';
import { Theme } from '@mui/material';
import { _var, CSS_VARIABLES, Layout } from '@shared/utils/layout';

export function styles(theme: Theme) {
  return appCreateStyles({
    root: {
      width: '100%',
      height: '100%',
      [theme.breakpoints.up(Layout.laptop)]: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',

        '& .MuiTextField-root': {
          backgroundColor: '#412D68',
          height: 'auto',
        },
        '& .error.MuiTextField-root': {
          background: '#2B112D',
          height: 'auto',
        },
      },
    },
    securityBlock: {
      [theme.breakpoints.up(Layout.laptop)]: {
        borderRadius: '12px',
        background: 'transparent',
      },
      [theme.breakpoints.down(Layout.tablet)]: {
        width: '100%',
        height: '100%',
        borderRadius: '12px',
      },
    },
    security: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    title: {
      color: _var(CSS_VARIABLES.colorAdditionalWhite),
      textAlign: 'center',
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: '165%',
      letterSpacing: '0.4px',
      [theme.breakpoints.up(Layout.laptop)]: {
        color: '#fff',
      },
    },
    backButton: {
      position: 'absolute',
      left: '20px',
      top: '20px',
      [theme.breakpoints.up(Layout.laptop)]: {
        display: 'none',
      },
    },
    content: {
      marginTop: '30px',
    },
    description: {
      color: '#778',
      fontSize: '13px',
      fontWeight: 500,
      lineHeight: '17px',
      letterSpacing: '0.2px',
      padding: '10px',
      marginBottom: '20px',
    },
    sectionTitle: {
      color: '#778',
      fontSize: '14px',
      fontWeight: 600,
      letterSpacing: '0.2px',
      paddingLeft: '10px',
      marginBottom: '16px',
    },
    fields: {
      marginTop: '30px',
    },
    field: {
      '& + &': {
        marginTop: '20px',
      },
    },
    label: {
      color: '#778',
      fontSize: '13px',
      fontWeight: 600,
      lineHeight: '22px',
      letterSpacing: '0.3px',
      opacity: '0.6',
      [theme.breakpoints.up(Layout.laptop)]: {
        opacity: '1',
        marginLeft: '8px',
        marginBottom: '8px',
      },
    },
  });
}
