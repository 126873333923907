import React, { useMemo } from 'react';

import { appObserver } from '@core/state-management/utils';
import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { Theme, useMediaQuery } from '@mui/material';
import { Loading } from '@shared/components/loading';
import { BackButton } from '@shared/components/new-design/back-button/back-button';
import { useNavigate } from '@shared/components/router';
import { ROUTES } from '@shared/constants/routes';
import { Layout } from '@shared/utils/layout';
import classNames from 'classnames';
import { useQuery } from 'react-query';

import TwoFAIcon from '../../img/2fa.svg';
import PasswirdResetIcon from '../../img/password-reset.svg';
import { SecurityViewModel } from './security.vm';

import { styles } from './security.styles';

export interface SecurityProps extends AppWithStyles<typeof styles> {}

const SecurityComponent: React.FC<SecurityProps> = appObserver(({ classes }) => {
  const $vm = useMemo(() => new SecurityViewModel(), []);

  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(Layout.tablet));

  const navigateToSettings = () => {
    navigate(ROUTES.mobilePrivate.settings.root);
  };

  const navigateToChangePassword = () => {
    isMobile
      ? navigate(ROUTES.mobilePrivate.settings.security.changePassword)
      : navigate(ROUTES.desktopPrivate.settings.security.changePassword);
  };

  const navigateToChangePhone = () => {
    isMobile
      ? navigate(ROUTES.mobilePrivate.settings.security.phone)
      : navigate(ROUTES.desktopPrivate.settings.security.phone);
  };

  const fetchUserInfoQuery = useQuery(['fetch-user-info'], () => $vm.fetchUserInfo(), {
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false,
  });

  let content = <></>;

  if (fetchUserInfoQuery.isFetching) {
    content = <Loading size={70} />;
  } else {
    content = (
      <div className={classes.content}>
        <div className={classes.section}>
          <ul className={classes.menu}>
            <li
              className={classNames(classes.menuItem, classes.withArrow)}
              onClick={navigateToChangePhone}
            >
              <div className={classes.flex}>
                <div className={classes.fieldIcon}>
                  <img src={String(TwoFAIcon)} />
                </div>
                <div className={classes.fieldName}>{t`2FA`}</div>
              </div>
              <div className={classes.fieldValue}>
                {$vm.isUserPhoneVerified ? (
                  <span className={classes.verified}>{t`Verified`}</span>
                ) : (
                  <span className={classes.notVerified}>{t`Not Verified`}</span>
                )}
              </div>
            </li>
          </ul>
        </div>
        <div
          className={classes.description}
        >{t`2FA is used to make access to account more secure. Every time you log into the app, you will receive an email with a passcode required to log in.`}</div>
        <div className={classes.section}>
          <div className={classes.sectionTitle}>{t`Verification`}</div>
          <ul className={classes.menu}>
            <li
              className={classNames(classes.menuItem, classes.withArrow)}
              onClick={navigateToChangePassword}
            >
              <div className={classes.flex}>
                <div className={classes.fieldIcon}>
                  <img src={String(PasswirdResetIcon)} />
                </div>
                <div className={classes.fieldName}>{t`Password reset`}</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.securityBlock}>
        <div className={classes.security}>
          <div>
            <div className={classes.backButton}>
              <BackButton onClick={navigateToSettings} />
            </div>
            <div className={classes.title}>{t`Account security`}</div>
            {content}
          </div>
        </div>
      </div>
    </div>
  );
});

export default appWithStyles(styles)(SecurityComponent);
