import { appInject } from '@core/di/utils';
import { DI_TOKENS } from '@shared/constants/di';
import { AuthGetModel } from '@shared/models/auth/get-model';
import { IAuthService } from '@shared/types/auth-service';

export class SecurityViewModel {
  private authService = appInject<IAuthService>(DI_TOKENS.authService);
  private _userInfo: AuthGetModel | null = null;

  get isUserPhoneVerified(): boolean {
    return Boolean(this._userInfo?.isPhoneVerified && this._userInfo.phone);
  }

  fetchUserInfo = async () => {
    this._userInfo = await this.authService.getUserInfo(false);
  };
}
