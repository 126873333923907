import { t } from '@lingui/macro';
import DesktopContainer from '@shared/components/new-design/containers/desktop-container/desktop-container';
import MobileContainer from '@shared/components/new-design/containers/mobile-container/mobile-container';
import { ROUTES } from '@shared/constants/routes';

import HelpCenter from './help-center';

const routes = [
  { label: t`Settings`, path: ROUTES.desktopPrivate.settings.root },
  { label: t`Help Center`, path: ROUTES.desktopPrivate.settings.security.root, disabled: true },
];

export const DesktopHelpCenter = () => (
  <DesktopContainer routes={routes}>
    <HelpCenter />
  </DesktopContainer>
);

export const MobileHelpCenter = () => (
  <MobileContainer>
    <HelpCenter />
  </MobileContainer>
);
