import React from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';

import Notifications from '../notifications/notifications';

import { styles } from './notifications-mobile-container.styles';

export interface DocumentsMobileContainerProps extends AppWithStyles<typeof styles> {}

const DocumentsMobileContainerComponent: React.FC<DocumentsMobileContainerProps> = ({
  classes,
}) => {
  return (
    <div className={classes.root}>
      <Notifications />
    </div>
  );
};

export default appWithStyles(styles)(DocumentsMobileContainerComponent);
