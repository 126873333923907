import React, { KeyboardEvent, useState } from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { Theme, useMediaQuery } from '@mui/material';
import { BackButton } from '@shared/components/new-design/back-button/back-button';
import { InputText } from '@shared/components/new-design/inputs-with-gray-background/input-text';
import SubmitButton from '@shared/components/new-design/submit-button';
import { useNavigate } from '@shared/components/router';
import { ROUTES } from '@shared/constants/routes';
import { Layout } from '@shared/utils/layout';
import { nameOf } from '@shared/utils/nameof';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import {
  ChangePasswordFieldsResolver,
  ChangePasswordFormFields,
  IChangePasswordForm,
} from './change-password.validator';
import { ChangePasswordViewModel } from './change-password.vm';

import { styles } from './change-password.styles';

export interface ChangePasswordProps extends AppWithStyles<typeof styles> {}

const ChangePasswordComponent: React.FC<ChangePasswordProps> = ({ classes }) => {
  const navigate = useNavigate();
  const [authError, setAuthError] = useState<string>('');
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(Layout.tablet));
  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<ChangePasswordFormFields>({ resolver: ChangePasswordFieldsResolver });

  const navigateToSecurity = () => {
    isMobile
      ? navigate(ROUTES.mobilePrivate.settings.security.root)
      : navigate(ROUTES.desktopPrivate.settings.security.root);
  };

  const changePasswordMutation = useMutation(
    (data: { oldPassword: string; password: string }) =>
      $vm.changePassword(data.oldPassword, data.password),
    {
      onSuccess: () => {
        navigate(ROUTES.mobilePrivate.settings.security.root);
        $vm.showMessage(t`Password was changed successfully`);
      },
      onError: (error: Error) => {
        setAuthError(error.message);
      },
    },
  );

  const resetValidation = () => {
    setAuthError('');
    changePasswordMutation.reset();
  };

  const onSubmitFrom = ({ oldPassword, password }: IChangePasswordForm) => {
    changePasswordMutation.mutate({ oldPassword, password });
  };

  const $vm = React.useMemo(() => new ChangePasswordViewModel(), []);

  const handleKeyPress = (e: KeyboardEvent<HTMLFormElement>) => {
    if (e.code === 'Enter') {
      handleSubmit(onSubmitFrom)();
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.securityBlock}>
        <form
          className={classes.security}
          onSubmit={handleSubmit(onSubmitFrom)}
          onKeyDown={handleKeyPress}
        >
          <div>
            <div className={classes.backButton}>
              <BackButton onClick={navigateToSecurity} />
            </div>
            <div className={classes.title}>{t`Password reset`}</div>
            <div className={classes.content}>
              <div className={classes.fields}>
                <div className={classes.field}>
                  <div className={classes.label}>{t`Old Password`}</div>
                  <InputText
                    type={'password'}
                    autoFocus={true}
                    controls={register(nameOf<IChangePasswordForm>('oldPassword'))}
                    resetValidation={resetValidation}
                    helperText={''}
                    error={Boolean(authError)}
                  />
                </div>
                <div className={classes.field}>
                  <div className={classes.label}>{t`Create New Password`}</div>
                  <InputText
                    type={'password'}
                    controls={register(nameOf<IChangePasswordForm>('password'))}
                    resetValidation={() => {}}
                    helperText={''}
                    error={Boolean(authError)}
                  />
                </div>
              </div>
              <div
                className={classes.description}
              >{t`Password shall consist of min 8 characters, at least one upper-case character, one number, one special character`}</div>
            </div>
          </div>
          <SubmitButton
            onSubmit={handleSubmit(onSubmitFrom)}
            label={t`Continue`}
            disabled={!isValid}
            isLoading={changePasswordMutation.isLoading}
          />
        </form>
      </div>
    </div>
  );
};

export default appWithStyles(styles)(ChangePasswordComponent);
