import React from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';

import ChangePassword from '../change-password/change-password';

import { styles } from './change-password-mobile-container.styles';

export interface SecurityMobileContainerProps extends AppWithStyles<typeof styles> {}

const SecurityMobileContainerComponent: React.FC<SecurityMobileContainerProps> = ({ classes }) => {
  return (
    <div className={classes.root}>
      <ChangePassword />
    </div>
  );
};

export default appWithStyles(styles)(SecurityMobileContainerComponent);
