import React from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';

import Security from '../security/security';

import { styles } from './secutity-mobile-container.styles';

export interface SecurityMobileContainerProps extends AppWithStyles<typeof styles> {}

const SecurityMobileContainerComponent: React.FC<SecurityMobileContainerProps> = ({ classes }) => {
  return (
    <div className={classes.root}>
      <Security />
    </div>
  );
};

export default appWithStyles(styles)(SecurityMobileContainerComponent);
