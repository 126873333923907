import { appCreateStyles } from '@core/theme/utils/create-styles';
import { appWithStyles } from '@core/theme/utils/with-styles';
import { Theme, IconButton, TextField, useMediaQuery } from '@mui/material';
import { IconButtonProps } from '@mui/material/IconButton/IconButton';
import { AppSvgIcon } from '@shared/components/svg-icon';
import { _var, CSS_VARIABLES, Layout } from '@shared/utils/layout';

import ChangeAvatarMobileSvg from '../img/change-avatar-mobile.svg';
import ChangeAvatarDesktopSvg from '../img/change-avater-desktop.svg';

export function styles(theme: Theme) {
  return appCreateStyles({
    root: {
      width: '100%',
      height: _var(CSS_VARIABLES.appHeight),
      overflow: 'auto',
      [theme.breakpoints.down(Layout.tablet)]: {
        '& .MuiCircularProgress-root': {
          color: '#0A84FF !important',
        },
      },
      [theme.breakpoints.up(Layout.laptop)]: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
      },
    },
    changeAvatarButton: {
      position: 'absolute',
      width: '30px',
      height: '30px',
      left: '50px',
      top: '50px',
      cursor: 'pointer',
      backgroundRepeat: 'no-repeat',
      [theme.breakpoints.up(Layout.laptop)]: {
        backgroundImage: `url(${ChangeAvatarDesktopSvg})`,
      },
      [theme.breakpoints.down(Layout.tablet)]: {
        backgroundImage: `url(${ChangeAvatarMobileSvg})`,
      },
    },
    personalInfoBlock: {
      [theme.breakpoints.up(Layout.laptop)]: {
        borderRadius: '12px',
        width: '400px',
        background: 'transparent',
      },
      [theme.breakpoints.down(Layout.tablet)]: {
        width: '100%',
        height: '100%',
        borderRadius: '12px',
      },
    },
    personalInfo: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    title: {
      color: _var(CSS_VARIABLES.colorAdditionalWhite),
      textAlign: 'center',
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: '165%',
      letterSpacing: '0.4px',
      [theme.breakpoints.up(Layout.laptop)]: {
        display: 'none',
      },
    },
    backButton: {
      position: 'absolute',
      left: '20px',
      top: '20px',
      [theme.breakpoints.up(Layout.laptop)]: {
        display: 'none',
      },
    },
    menu: {},
    menuItem: {
      backgroundColor: '#21203B',
      height: '48px',
      width: '100%',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0px 16px',

      '& + &': {
        borderTop: '0.5px solid rgba(255, 255, 255, 0.10)',
      },
      '&:first-of-type': {
        borderRadius: '12px 12px 0px 0px',
      },
      '&:last-of-type': {
        borderRadius: '0px 0px 12px 12px',
      },
      [theme.breakpoints.up(Layout.laptop)]: {
        background: '#412D68',
      },
    },
    fieldName: {
      overflow: 'hidden',
      color: '#fff',
      fontFeatureSettings: "'clig' off, 'liga' off",
      textOverflow: 'ellipsis',
      wordBreak: 'keep-all',
      whiteSpace: 'nowrap',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '22px',
      letterSpacing: '0.3px',
      minWidth: 'fit-content',
    },
    fieldValue: {
      color: '#778',
      fontSize: '14px',
      fontWeight: 600,
      letterSpacing: '0.2px',
      maxWidth: '100%',
      textWrap: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      wordBreak: 'keep-all',
      marginLeft: '12px',
      [theme.breakpoints.up(Layout.laptop)]: {
        color: '#A4A8AB',
      },
    },
    section: {},
    userIcon: {
      height: '138px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.up(Layout.laptop)]: {
        height: 'auto',
        marginBottom: '20px',
      },
    },
    image: {
      width: '80px',
      height: '80px',
    },
    icon: {
      position: 'relative',
    },
    initials: {
      color: '#FFF',
      fontSize: '21px',
      fontWeight: 600,
      lineHeight: '22px',
      letterSpacing: '0.3px',
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    flex: {
      display: 'flex',
    },
    address: {
      height: '72px',
      '& $fieldValue': {
        textWrap: 'wrap',
        textAlign: 'right',
      },
    },
    editable: {
      '& $fieldValue': {
        display: 'flex',
        alignItems: 'center',
        margin: '0px 12px',
      },
    },
    saveButton: {
      textAlign: 'right',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '160%',
      letterSpacing: '0.2px',
    },
    active: {
      color: '#0A84FF',
      cursor: 'pointer',
    },
    disabled: {
      color: '#D1D1D1',
    },
  });
}

const StyledIconButton = appWithStyles(() => ({
  root: {
    padding: '8px 0px 8px 8px',
  },
}))(IconButton);

const StyledSvgIcon = appWithStyles(() => ({
  root: {
    width: '26px',
    height: '26px',
  },
}))(AppSvgIcon);

export const PencilButton: React.FC<IconButtonProps> = (props) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(Layout.tablet));
  const backgroundColor = isMobile ? '#444676' : '#604C87';
  return (
    <StyledIconButton {...props}>
      <StyledSvgIcon width="26" height="26" viewBox="0 0 26 26" fill="none">
        <rect width="26" height="26" rx="13" fill={backgroundColor} />
        <path
          d="M18.3421 7.65797C18.1335 7.44937 17.8859 7.2839 17.6134 7.171C17.3409 7.05811 17.0488 7 16.7538 7C16.4589 7 16.1668 7.05811 15.8943 7.171C15.6218 7.2839 15.3742 7.44937 15.1656 7.65797L8.42037 14.4035C8.10001 14.7241 7.87157 15.1248 7.75887 15.5638L7.01339 18.4653C6.99491 18.5374 6.99557 18.6132 7.01531 18.6851C7.03506 18.7569 7.0732 18.8224 7.12598 18.875C7.17876 18.9276 7.24435 18.9655 7.31627 18.9851C7.38819 19.0046 7.46395 19.005 7.53608 18.9863L10.4366 18.2416C10.8756 18.1291 11.2763 17.9006 11.5968 17.5801L18.3421 10.8345C18.5506 10.626 18.7161 10.3783 18.829 10.1058C18.9419 9.83331 19 9.54122 19 9.24624C19 8.95127 18.9419 8.65918 18.829 8.38666C18.7161 8.11414 18.5506 7.86653 18.3421 7.65797ZM15.7715 8.2638C16.032 8.00324 16.3854 7.85686 16.7538 7.85686C17.1223 7.85686 17.4757 8.00324 17.7362 8.2638C17.9968 8.52436 18.1432 8.87776 18.1432 9.24624C18.1432 9.61473 17.9968 9.96813 17.7362 10.2287L17.0687 10.8962L15.1039 8.93133L15.7715 8.2638ZM14.4981 9.53716L16.4629 11.502L10.991 16.9742C10.7788 17.186 10.5136 17.337 10.2232 17.4113L8.02364 17.9768L8.58917 15.7771C8.66293 15.4865 8.81393 15.2212 9.02618 15.0094L14.4981 9.53716Z"
          fill="white"
        />
      </StyledSvgIcon>
    </StyledIconButton>
  );
};

export const StyledTextField = appWithStyles(() => ({
  root: {
    '& .MuiOutlinedInput-input': {
      padding: '0px',
      margin: '0px 20px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  focused: {},
}))(TextField);
