import React, { useMemo } from 'react';

import { appObserver } from '@core/state-management/utils';
import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import EmailIcon from '@modules/new-private/settings/components/notifications/components/notifications/img/email.svg';
import PushIcon from '@modules/new-private/settings/components/notifications/components/notifications/img/push.svg';
import ToastIcon from '@modules/new-private/settings/components/notifications/components/notifications/img/toast.svg';
import { Loading } from '@shared/components/loading';
import { BackButton } from '@shared/components/new-design/back-button/back-button';
import { AppSwitch } from '@shared/components/new-design/switch';
import { useNavigate } from '@shared/components/router';
import { ROUTES } from '@shared/constants/routes';
import { UserSettings } from '@shared/models/users/details-model';
import classNames from 'classnames';
import { useMutation, useQuery } from 'react-query';

import { NotificationsViewModel } from './notifications.vm';

import { styles } from './notifications.styles';

export interface NotificationsProps extends AppWithStyles<typeof styles> {}

const NotificationsComponent: React.FC<NotificationsProps> = appObserver(({ classes }) => {
  const $vm = useMemo(() => new NotificationsViewModel(), []);

  const navigate = useNavigate();

  const navigateToSettings = () => {
    navigate(ROUTES.mobilePrivate.settings.root);
  };

  const fetchUserInfoQuery = useQuery(['fetch-user-settings'], () => $vm.fetchUserSettings(), {
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false,
  });

  const updateSettingsMutation = useMutation((data: UserSettings) => $vm.updateSettings(data), {
    onSettled: () => {
      fetchUserInfoQuery.refetch();
    },
  });

  const handleChangePushNotifications = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateSettingsMutation.mutate({
      PushNotification: e.target.checked,
      EmailNotification: $vm.settings.EmailNotification,
    });
  };
  const handleChangeEmailNotifications = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateSettingsMutation.mutate({
      PushNotification: $vm.settings.PushNotification,
      EmailNotification: e.target.checked,
    });
  };

  let content = <></>;

  if (fetchUserInfoQuery.isFetching || updateSettingsMutation.isLoading) {
    return (
      <div className={classNames(classes.root, classes.invisibleContent, 'invisible-content')}>
        <Loading size={70} />
      </div>
    );
  } else {
    content = (
      <div className={classes.content}>
        <div className={classes.section}>
          <ul className={classes.menu}>
            <li className={classes.menuItem}>
              <div className={classes.flex}>
                <div className={classes.fieldIcon}>
                  <img src={String(PushIcon)} />
                </div>
                <div className={classes.fieldName}>{t`Push notifications`}</div>
              </div>
              <div className={classes.switcher}>
                <AppSwitch
                  checked={$vm.settings.PushNotification}
                  onChange={handleChangePushNotifications}
                />
              </div>
            </li>
          </ul>
        </div>
        <div
          className={classes.description}
        >{t`Stay informed about the service events even when you are not using the wealthstack app`}</div>
        <div className={classes.section}>
          <ul className={classes.menu}>
            <li className={classes.menuItem}>
              <div className={classes.flex}>
                <div className={classes.fieldIcon}>
                  <img src={String(EmailIcon)} />
                </div>
                <div className={classes.fieldName}>{t`Email notification`}</div>
              </div>
              <div className={classes.switcher}>
                <AppSwitch
                  checked={$vm.settings.EmailNotification}
                  onChange={handleChangeEmailNotifications}
                />
              </div>
            </li>
          </ul>
        </div>
        <div
          className={classes.description}
        >{t`Receive important messages about the system or actions directly to your email`}</div>
        <div className={classNames(classes.section, classes.disabled)}>
          <ul className={classes.menu}>
            <li className={classes.menuItem}>
              <div className={classes.flex}>
                <div className={classes.fieldIcon}>
                  <img src={String(ToastIcon)} />
                </div>
                <div className={classes.fieldName}>{t`Toast notification`}</div>
              </div>
              <div className={classes.switcher}>
                <AppSwitch checked={true} onChange={() => {}} />
              </div>
            </li>
          </ul>
        </div>
        <div
          className={classes.description}
        >{t`Toasts can help you keep an eye on your activities and changes in the market. That\`s why we advise to keep them on`}</div>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.securityBlock}>
        <div className={classes.security}>
          <div>
            <div className={classes.backButton}>
              <BackButton onClick={navigateToSettings} />
            </div>
            <div className={classes.title}>{t`Notifications`}</div>
            {content}
          </div>
        </div>
      </div>
    </div>
  );
});

export default appWithStyles(styles)(NotificationsComponent);
