import React, { FC, useEffect, useMemo, useState } from 'react';

import { appObserver } from '@core/state-management/utils';
import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { Loading } from '@shared/components/loading';
import { BackButton } from '@shared/components/new-design/back-button/back-button';
import DocumentsButton from '@shared/components/new-design/document-button/documents-button';
import { useNavigate } from '@shared/components/router';
import { ROUTES } from '@shared/constants/routes';
import { TermsDocumentModel } from '@shared/models/users/terms-documents-model';
import { useQuery } from 'react-query';

import { DocumentDialog } from '../document-dialog';
import { MobileDocumentsViewModel } from './documents.vm';

import { styles } from './documents.styles';

export interface MobileDocumentsProps extends AppWithStyles<typeof styles> {}

const MobileDocumentsComponent: FC<MobileDocumentsProps> = appObserver(({ classes }) => {
  const navigate = useNavigate();

  const navigateToSettings = () => {
    navigate(ROUTES.mobilePrivate.settings.root);
  };

  const [activeDocument, setActiveDocument] = useState<TermsDocumentModel | null>(null);
  const $vm = useMemo(() => new MobileDocumentsViewModel(), []);

  useEffect(() => {
    $vm.refreshAccepteDocuments();
  }, [$vm.termsDocuments.length]);

  const handleDocument = (document: TermsDocumentModel) => {
    setActiveDocument(document);
  };

  const fetchMyUserDetailsQuery = useQuery(
    ['fetch-my-user-details'],
    () => $vm.fetchMyUserDetails(),
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
    },
  );

  const fetchTermsDocumentsQuery = useQuery(
    ['fetch-terms-documents'],
    () => $vm.fetchTermsDocuments(),
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
    },
  );

  if (fetchMyUserDetailsQuery.isLoading || fetchTermsDocumentsQuery.isLoading) {
    return <Loading size={70} />;
  }

  const documentsButtons = $vm.termsDocuments.map((document: TermsDocumentModel, index: number) => {
    return (
      <div key={index} className={classes.field} onClick={() => handleDocument(document)}>
        <DocumentsButton
          label={document.title}
          number={index + 1}
          onClick={() => {}}
          isActive={$vm.acceptedDocuments.has(document.id)}
        />
      </div>
    );
  });

  return (
    <div className={classes.root}>
      <div className={classes.documentsBlock}>
        <div className={classes.documents}>
          <div>
            <div className={classes.backButton}>
              <BackButton onClick={navigateToSettings} />
            </div>
            <div className={classes.title}>{t`Documents`}</div>
            <div className={classes.description}>
              {t`Legal documents that define the conditions for using of wealthstack app. You accepted these documents when you created your user account.`}
            </div>
            <div className={classes.fields}>{documentsButtons}</div>
          </div>
        </div>
      </div>
      <DocumentDialog
        isOpen={Boolean(activeDocument)}
        onClose={() => setActiveDocument(null)}
        title={activeDocument?.title || ''}
        url={activeDocument?.url || ''}
        date={new Date(activeDocument?.lastUpdateDate || '')}
      />
    </div>
  );
});
export default appWithStyles(styles)(MobileDocumentsComponent);
