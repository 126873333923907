import { t } from '@lingui/macro';
import DesktopContainer from '@shared/components/new-design/containers/desktop-container/desktop-container';
import MobileContainer from '@shared/components/new-design/containers/mobile-container/mobile-container';
import { useParams } from '@shared/components/router';
import { ROUTES } from '@shared/constants/routes';
import { IssueType } from '@shared/enums/issue-type.enum';

import Issue from './issue';

const getTitleByIssueType = (issueType: IssueType) => {
  let title: string = '';
  switch (issueType as IssueType) {
    case IssueType.ACCOUNT_ISSUE:
      title = t`Account issue`;
      break;
    case IssueType.TRANSACTION_ISSUE:
      title = t`Transaction issue`;
      break;
    default:
      title = t`General question`;
  }
  return title;
};

export const DesktopIssue = () => {
  const { issueType } = useParams();
  const title = getTitleByIssueType(issueType as IssueType);

  const routes = [
    { label: t`Settings`, path: ROUTES.desktopPrivate.settings.root },
    { label: t`Help Center`, path: ROUTES.desktopPrivate.settings.helpCenter.root },
    {
      label: title,
      path: ROUTES.desktopPrivate.settings.helpCenter.issue(issueType as IssueType),
      disabled: true,
    },
  ];
  return (
    <DesktopContainer routes={routes}>
      <Issue title={title} type={issueType as IssueType} />
    </DesktopContainer>
  );
};

export const MobileIssue = () => {
  const { issueType } = useParams();
  const title = getTitleByIssueType(issueType as IssueType);

  return (
    <MobileContainer>
      <Issue title={title} type={issueType as IssueType} />
    </MobileContainer>
  );
};
