import { PasswordStrength } from '@core/forms/validators/password-strength';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { t } from '@lingui/macro';
import { IsString, MinLength, Validate } from 'class-validator';

export interface IChangePasswordForm {
  oldPassword: string;
  password: string;
}

export class ChangePasswordFormFields implements IChangePasswordForm {
  @IsString()
  @MinLength(1, { message: t`This field is mandatory` })
  oldPassword: string;

  @Validate(PasswordStrength)
  password: string;
}

export const ChangePasswordFieldsResolver = classValidatorResolver(ChangePasswordFormFields);
