import { appInject } from '@core/di/utils';
import { DI_TOKENS } from '@shared/constants/di';
import { UserSettings } from '@shared/models/users/details-model';
import { IUsersService } from '@shared/types/users-service';

export class NotificationsViewModel {
  private usersService = appInject<IUsersService>(DI_TOKENS.usersService);

  private _settings: UserSettings | any = {};

  constructor() {}

  get settings() {
    return this._settings;
  }

  async fetchUserSettings() {
    const userDetails = await this.usersService.getMyUserDetails();
    this._settings = userDetails.asJson.userSettings;
  }

  async updateSettings({ EmailNotification, PushNotification }: UserSettings) {
    await this.usersService.getUserSettings({ EmailNotification, PushNotification });
  }
}
