import React, { FC } from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { Box, Button, Drawer, Modal, Theme, useMediaQuery } from '@mui/material';
import { CloseDrawerButton } from '@shared/components/new-design/close-drawer-button/close-drawer-button';
import { getMonthName } from '@shared/utils/date';
import { Layout } from '@shared/utils/layout';
import classNames from 'classnames';

import { styles } from './document-dialog.styles';

export interface DocumentDialogProps extends AppWithStyles<typeof styles> {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  date: Date;
  url: string;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 535,
  background: '#291945',
  border: '1px solid #473763',
  borderRadius: '10px',
  '&:focus-visible': {
    outline: 'none',
  },
};

const DocumentDialogComponent: FC<DocumentDialogProps> = ({
  classes,
  onClose,
  isOpen,
  title,
  date,
  url,
}) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(Layout.tablet));

  const documentsComponent = (
    <div className={classes.viewBlock}>
      <div
        className={title.length > 20 ? classNames(classes.title, classes.longer) : classes.title}
      >
        {title}
      </div>
      <div className={classes.updatedDate}>
        {t`Dated`} {getMonthName(date)} {date.getFullYear()}
      </div>
      <div className={classes.iframeBlock}>
        <iframe className={classes.iframe} src={url}></iframe>
      </div>
      <div className={classes.closeButton}>
        <Button variant="contained" disabled={false} onClick={onClose}>
          {t`Close`}
        </Button>
      </div>
    </div>
  );

  let content = <></>;

  if (isMobile) {
    content = (
      <Drawer anchor={'bottom'} open={isOpen} className={classes.drawer} onClose={onClose}>
        <div className={classes.drawerContent}>{documentsComponent}</div>
        <CloseDrawerButton onClick={onClose} />
      </Drawer>
    );
  } else {
    content = (
      <Modal
        open={isOpen}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>{documentsComponent}</Box>
      </Modal>
    );
  }
  return <div className={classes.root}>{content}</div>;
};

export default appWithStyles(styles)(DocumentDialogComponent);
