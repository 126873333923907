import { appCreateStyles } from '@core/theme/utils/create-styles';

export function styles() {
  return appCreateStyles({
    root: {
      padding: '20px',
      width: '100%',
    },
  });
}
