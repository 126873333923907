import { appCreateStyles } from '@core/theme/utils/create-styles';
import ArrowRight from '@modules/new-private/settings/components/settings/img/arrow-right.svg';
import { Theme } from '@mui/material';
import { _var, CSS_VARIABLES, Layout } from '@shared/utils/layout';

export function styles(theme: Theme) {
  return appCreateStyles({
    root: {
      width: '100%',
      [theme.breakpoints.up(Layout.laptop)]: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
      },
    },
    securityBlock: {
      [theme.breakpoints.up(Layout.laptop)]: {
        borderRadius: '12px',
        background: 'transparent',
      },
      [theme.breakpoints.down(Layout.tablet)]: {
        width: '100%',
        height: '100%',
        borderRadius: '12px',
      },
    },
    security: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    title: {
      color: _var(CSS_VARIABLES.colorAdditionalWhite),
      textAlign: 'center',
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: '165%',
      letterSpacing: '0.4px',
      [theme.breakpoints.up(Layout.laptop)]: {
        color: '#fff',
      },
    },
    backButton: {
      position: 'absolute',
      left: '20px',
      top: '20px',
      [theme.breakpoints.up(Layout.laptop)]: {
        display: 'none',
      },
    },
    fieldName: {
      overflow: 'hidden',
      color: '#fff',
      fontFeatureSettings: "'clig' off, 'liga' off",
      textOverflow: 'ellipsis',
      wordBreak: 'keep-all',
      whiteSpace: 'nowrap',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '22px',
      letterSpacing: '0.3px',
      minWidth: 'fit-content',

      '$disabled &': {
        color: 'rgba(255, 255, 255, 0.20) !important',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '22px',
        letterSpacing: '0.3px',
      },
    },
    fieldValue: {
      color: '#778',
      fontSize: '14px',
      fontWeight: 600,
      letterSpacing: '0.2px',
      maxWidth: '100%',
      textWrap: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      wordBreak: 'keep-all',
      marginLeft: '12px',
      [theme.breakpoints.up(Layout.laptop)]: {
        color: '#A4A8AB',
      },
    },
    section: {},
    menu: {},
    menuItem: {
      cursor: 'pointer',
      backgroundColor: '#21203B',
      height: '48px',
      width: '100%',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0px 16px',

      '& + &': {
        borderTop: '0.5px solid rgba(255, 255, 255, 0.10)',
      },
      '&:first-of-type': {
        borderRadius: '12px 12px 0px 0px',
      },
      '&:last-of-type': {
        borderRadius: '0px 0px 12px 12px',
      },
      '&:first-of-type&:last-of-type': {
        borderRadius: '12px 12px 12px 12px',
      },
      [theme.breakpoints.up(Layout.laptop)]: {
        background: '#412D68',
      },
    },
    content: {
      marginTop: '30px',
    },
    fieldIcon: {
      marginRight: '9px',
      display: 'flex',
      alignItems: 'center',
    },
    flex: {
      display: 'flex',
    },
    withArrow: {
      cursor: 'pointer',
      backgroundImage: `url(${ArrowRight})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right 16px center',
      paddingRight: '36px !important',
    },
    description: {
      color: '#778',
      fontSize: '13px',
      fontWeight: 500,
      lineHeight: '17px',
      letterSpacing: '0.2px',
      padding: '10px',
      marginBottom: '20px',
      [theme.breakpoints.up(Layout.laptop)]: {
        color: '#A4A8AB',
      },
    },
    sectionTitle: {
      color: '#778',
      fontSize: '14px',
      fontWeight: 600,
      letterSpacing: '0.2px',
      paddingLeft: '10px',
      marginBottom: '16px',
      [theme.breakpoints.up(Layout.laptop)]: {
        color: '#A4A8AB',
      },
    },
    switcher: {
      '& .MuiFormControlLabel-root': {
        marginRight: '0px',
      },
    },
    disabled: {},
    loading: {
      width: '100%',
      minHeight: '70vmin',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.up(Layout.laptop)]: {
        minHeight: '0px',
      },
    },
    invisibleContent: {
      height: '100%',
    },
  });
}
