import React from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';

import ChangePhone from '../change-phone/change-phone';

import { styles } from './change-phone-mobile-container.styles';

export interface SecurityMobileContainerProps extends AppWithStyles<typeof styles> {}

const ChangePhoneMobileContainerComponent: React.FC<SecurityMobileContainerProps> = ({
  classes,
}) => {
  return (
    <div className={classes.root}>
      <ChangePhone />
    </div>
  );
};

export default appWithStyles(styles)(ChangePhoneMobileContainerComponent);
