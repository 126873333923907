import React from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';

import Settings from '../settings/settings';

import { styles } from './mobile-settings-container.styles';

export interface MobileSettingsProps extends AppWithStyles<typeof styles> {}

const MobileSettings: React.FC<MobileSettingsProps> = ({ classes }) => {
  return (
    <div className={classes.root}>
      <Settings />
    </div>
  );
};

export default appWithStyles(styles)(MobileSettings);
