import { appInject } from '@core/di/utils';
import { appMakeObservable, appObservable } from '@core/state-management/utils';
import { IssueRepository } from '@modules/new-private/settings/components/issue/issue-repository';
import { DI_TOKENS } from '@shared/constants/di';
import { IssueType } from '@shared/enums/issue-type.enum';
import { SendSupportMessageDto } from '@shared/models/help-center/send-support-message.dto';
import { IAuthService } from '@shared/types/auth-service';
import { IConfigService } from '@shared/types/config-service';
import { IUsersService } from '@shared/types/users-service';
import { browser } from '@shared/utils/browser';

export class IssueViewModel {
  private configService = appInject<IConfigService>(DI_TOKENS.configService);
  private authService = appInject<IAuthService>(DI_TOKENS.authService);
  private usersService = appInject<IUsersService>(DI_TOKENS.usersService);
  private _browser;
  private _type: IssueType;
  private _issueRepository: IssueRepository;
  private _state = {
    transactionId: '',
    transactionIdError: true as boolean,
    body: '',
    bodyError: true as boolean,
  };

  constructor(type: IssueType) {
    this._type = type;
    this._issueRepository = new IssueRepository().setType(type);
    const data = this._issueRepository.getFromStorage();
    this._browser = browser;
    setTimeout(() => {
      this._state.transactionId = data.transactionId || '';
      this._state.body = data.body || '';
      this.validate();
    }, 0);
    appMakeObservable(this, {
      _state: appObservable,
    });
  }

  get version() {
    return this.configService.version;
  }

  get browserInfo() {
    return `${this._browser?.os} ${this._browser?.name} ${this._browser?.version}`;
  }

  get supportEmail() {
    return this.configService.supportEmail;
  }

  get userId() {
    return this.authService.userId;
  }

  get email() {
    return this.authService.email;
  }

  get transactionId() {
    return this._state.transactionId;
  }

  get transactionIdError() {
    return this._state.transactionIdError;
  }

  get body() {
    return this._state.body;
  }

  get bodyError() {
    return this._state.bodyError;
  }

  isValidTransactionId(): boolean {
    if (this._type !== IssueType.TRANSACTION_ISSUE) {
      return true;
    }
    return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/.test(
      this._state.transactionId,
    );
  }

  isValidBody() {
    return /^.{20,250}$/.test(this._state.body);
  }

  validate() {
    this._state.transactionIdError = !this.isValidTransactionId();
    this._state.bodyError = !this.isValidBody();
    return !this._state.transactionIdError && !this._state.bodyError;
  }

  getSubjectByIssueType(issueType: IssueType) {
    let subject: string = '';
    switch (issueType as IssueType) {
      case IssueType.ACCOUNT_ISSUE:
        subject = `Account issue`;
        break;
      case IssueType.TRANSACTION_ISSUE:
        subject = `Transaction issue`;
        break;
      default:
        subject = `General question`;
    }
    return subject;
  }

  refreshStorage() {
    this._issueRepository.removeFromStorage();
  }

  handleChangeTransactionId(transactionId: string) {
    this.validate();
    this._state.transactionId = transactionId;
    this._issueRepository.saveToStorage({
      transactionId: this._state.transactionId,
      body: this._state.body,
    });
  }

  handleChangeBody(text: string) {
    this.validate();
    this._state.body = text;
    this._issueRepository.saveToStorage({
      transactionId: this._state.transactionId,
      body: this._state.body,
    });
  }

  sendMessage(data: SendSupportMessageDto) {
    this.validate();
    return this.usersService.sendSupportMessage(data);
  }
}
