import React, { KeyboardEvent, useMemo, useState } from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { Theme, useMediaQuery } from '@mui/material';
import { Loading } from '@shared/components/loading';
import { BackButton } from '@shared/components/new-design/back-button/back-button';
import { DrawerDialog } from '@shared/components/new-design/drawer-dialog';
import { InputPhone } from '@shared/components/new-design/inputs-with-gray-background/input-phone';
import { OtpView } from '@shared/components/new-design/otp-view';
import SubmitButton from '@shared/components/new-design/submit-button';
import { useNavigate } from '@shared/components/router';
import { ROUTES } from '@shared/constants/routes';
import { Layout } from '@shared/utils/layout';
import { nameOf } from '@shared/utils/nameof';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';

import {
  IPhoneViewForm,
  PhoneViewFieldsResolver,
  PhoneViewFormFields,
} from './change-phone.validator';
import { SecurityViewModel } from './change-phone.vm';

import { styles } from './change-phone.styles';

export interface ChangePhoneProps extends AppWithStyles<typeof styles> {}

const ChangePhoneComponent: React.FC<ChangePhoneProps> = ({ classes }) => {
  const $vm = useMemo(() => new SecurityViewModel(), []);
  const [authError, setAuthError] = useState<string>('');
  const [otpError, setOtpError] = useState<string>('');
  const [lastOtpRequestDate, setLastOtpRequestDate] = useState<Date>($vm.loadLastOtpRequestDate());
  const [isOpenOtpDialog, setIsOpenOtpDialog] = useState<boolean>(false);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(Layout.tablet));

  const {
    register,
    trigger,
    getValues,
    handleSubmit,
    formState: { isValid },
  } = useForm<PhoneViewFormFields>({ resolver: PhoneViewFieldsResolver });

  const navigate = useNavigate();

  const fetchMyUserDetailsQuery = useQuery(['fetch-my-user-data'], () => $vm.fetchMyUserDetails(), {
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false,
  });

  const fetchUserInfoQuery = useQuery(['fetch-user-info'], () => $vm.fetchUserInfo(), {
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false,
  });

  const requestCodeMutation = useMutation(
    (data: { phoneNumber: string }) => $vm.requestCode(data.phoneNumber),
    {
      onSuccess: async () => {
        setIsOpenOtpDialog(true);
        const newDate = new Date();
        setLastOtpRequestDate(newDate);
        $vm.saveLastOtpRequestDate(newDate);
      },
      onError: (e: Error) => {
        setAuthError(e.message);
      },
    },
  );

  const sendCodeMutation = useMutation((data: { otp: string }) => $vm.sendCode(data.otp), {
    onSuccess: () => {
      setLastOtpRequestDate(new Date());
      $vm.saveLastOtpRequestDate(new Date());
      isMobile
        ? navigate(ROUTES.mobilePrivate.settings.security.root)
        : navigate(ROUTES.desktopPrivate.settings.security.root);
      $vm.showMessage(t`2FA enabled successfully`);
    },
    onError: () => {
      setOtpError(t`Invalid code`);
    },
  });

  const requestResendCodeMutation = useMutation(() => $vm.resendCode(getValues().phoneNumber), {
    onSuccess: () => {
      const newDate = new Date();
      setLastOtpRequestDate(newDate);
      $vm.saveLastOtpRequestDate(newDate);
    },
    onError: (e: Error) => {
      setAuthError(e.message);
    },
  });

  const onSubmitOtp = (otp: string) => {
    sendCodeMutation.mutate({ otp });
  };

  const handleResendCode = () => {
    requestResendCodeMutation.mutate();
  };

  const navigateToSettings = () => {
    navigate(ROUTES.mobilePrivate.settings.security.root);
  };

  const handleOnChange = () => {
    trigger(nameOf<IPhoneViewForm>('phoneNumber'));
  };

  const onSubmitFrom = () => {
    requestCodeMutation.mutate({ phoneNumber: getValues().phoneNumber });
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLFormElement>) => {
    if (e.code === 'Enter' && isValid) {
      handleSubmit(onSubmitFrom)();
    }
  };

  const isPhoneNumberChanged = () => {
    return $vm.userPhone !== getValues().phoneNumber;
  };

  let content = <></>;

  if (fetchUserInfoQuery.isFetching || fetchMyUserDetailsQuery.isFetching) {
    content = <Loading size={70} />;
  } else {
    content = (
      <div className={classes.content}>
        <div
          className={classes.description}
        >{t`You need to verify your phone number in order to enable two-factor authentication. That will help improve security and protect your privacy.`}</div>

        <div className={classes.fields}>
          <div className={classes.field}>
            <div className={classes.sectionTitle}>{t`Enter Phone #`}</div>
            <InputPhone
              defaultValue={$vm.userPhone || $vm.userPhoneFromOnbarding}
              autoFocus={true}
              controls={register(nameOf<IPhoneViewForm>('phoneNumber'))}
              onChangeValue={handleOnChange}
              resetValidation={() => {}}
              helperText={''}
              error={false}
            />
          </div>
        </div>
      </div>
    );
  }

  const otpForm = (
    <OtpView
      title={t`Enter code`}
      subTitle={`${t`Please verify with the 6-digits code sent you to`} ${getValues().phoneNumber}`}
      timerDate={lastOtpRequestDate}
      timerDelayMinutes={$vm.mfaDelayMinutes}
      error={otpError || authError}
      onResend={handleResendCode}
      onBack={() => setIsOpenOtpDialog(false)}
      onSubmit={onSubmitOtp}
      isLoading={sendCodeMutation.isLoading}
    />
  );

  return (
    <div className={classes.root}>
      <div className={classes.securityBlock}>
        <form
          className={classes.security}
          onSubmit={handleSubmit(onSubmitFrom)}
          onKeyDown={handleKeyPress}
        >
          <div>
            <div className={classes.backButton}>
              <BackButton onClick={navigateToSettings} />
            </div>
            <div className={classes.title}>{t`Phone # verification`}</div>
            {content}
          </div>
          <SubmitButton
            onSubmit={handleSubmit(onSubmitFrom)}
            label={t`Send Me Verification Code`}
            disabled={!isValid || !isPhoneNumberChanged()}
            isLoading={requestCodeMutation.isLoading}
          />
        </form>
      </div>
      <DrawerDialog isOpen={isOpenOtpDialog} onClose={() => setIsOpenOtpDialog(false)} height={90}>
        {otpForm}
      </DrawerDialog>
    </div>
  );
};

export default appWithStyles(styles)(ChangePhoneComponent);
