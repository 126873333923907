import { appInject } from '@core/di/utils';
import { DI_TOKENS } from '@shared/constants/di';
import { AuthGetModel } from '@shared/models/auth/get-model';
import { UserDetailsModel } from '@shared/models/users/details-model';
import { IAuthService } from '@shared/types/auth-service';
import { IConfigService } from '@shared/types/config-service';
import { INotificationService } from '@shared/types/notification-service';
import { IStorageService, StorageType } from '@shared/types/storage-service';
import { IUsersService } from '@shared/types/users-service';
import moment from 'moment';

export const LAST_MFA_ENABLE_REQUEST_TIME_KEY = 'LAST_MFA_ENABLE_REQUEST_TIME_KEY';

export class SecurityViewModel {
  private authService = appInject<IAuthService>(DI_TOKENS.authService);
  private storageService = appInject<IStorageService>(DI_TOKENS.storageService);
  private configService = appInject<IConfigService>(DI_TOKENS.configService);
  private notificationsService = appInject<INotificationService>(DI_TOKENS.notificationService);
  private usersService = appInject<IUsersService>(DI_TOKENS.usersService);
  private _userInfo: AuthGetModel | null = null;
  private _me = {
    data: null as UserDetailsModel | null,
  };

  get isUserPhoneVerified(): boolean {
    return Boolean(this._userInfo?.isPhoneVerified && this._userInfo.phone);
  }

  get me() {
    return this._me.data;
  }

  get userPhoneFromOnbarding() {
    return this._me.data?.asJson.phone || '';
  }

  get userPhone(): string | undefined {
    return this._userInfo?.phone;
  }

  get userEmail(): string | undefined {
    return this._userInfo?.email;
  }

  get mfaDelayMinutes() {
    return this.configService.mfaDelayMinutes;
  }

  requestCode = async (phoneNumber: string) => {
    await this.authService.updateUserPhone(phoneNumber);
    await this.authService.updateMFAPreference(false);
  };

  resendCode = async (phoneNumber: string = '') => {
    return await this.authService.updateUserPhone(phoneNumber);
  };

  sendCode = async (code: string) => {
    await this.authService.confirmPhone(code);
    await this.authService.updateMFAPreference(true);
  };

  isOtpActive(lastOtpRequestDate: Date | null): boolean {
    if (lastOtpRequestDate) {
      const inXminutes = moment(lastOtpRequestDate)
        .add(this.configService.mfaDelayMinutes, 'm')
        .toDate();
      return inXminutes > new Date();
    }
    return false;
  }

  loadLastOtpRequestDate() {
    const storedOtpRequestDate: string | undefined = this.storageService.get(
      StorageType.localStorage,
      LAST_MFA_ENABLE_REQUEST_TIME_KEY,
    );
    return storedOtpRequestDate ? new Date(storedOtpRequestDate) : new Date();
  }

  saveLastOtpRequestDate(lastOtpRequestDate: Date | null) {
    if (lastOtpRequestDate) {
      this.storageService.set(
        StorageType.localStorage,
        LAST_MFA_ENABLE_REQUEST_TIME_KEY,
        lastOtpRequestDate,
      );
    } else {
      this.storageService.remove(StorageType.localStorage, LAST_MFA_ENABLE_REQUEST_TIME_KEY);
    }
  }

  fetchUserInfo = async () => {
    this._userInfo = await this.authService.getUserInfo(false);
  };

  async fetchMyUserDetails() {
    this._me.data = await this.usersService.getMyUserDetails();
  }

  showMessage(text: string) {
    return this.notificationsService.showSuccess(text);
  }
}
