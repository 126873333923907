import { appCreateStyles } from '@core/theme/utils/create-styles';
import { Theme } from '@mui/material';
import { _var, CSS_VARIABLES, Layout } from '@shared/utils/layout';

export function styles(theme: Theme) {
  return appCreateStyles({
    root: {
      width: '100%',
      [theme.breakpoints.up(Layout.laptop)]: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
      },
    },
    issueBlock: {
      [theme.breakpoints.up(Layout.laptop)]: {
        borderRadius: '12px',
        width: '100%',
        background: 'transparent',
      },
      [theme.breakpoints.down(Layout.tablet)]: {
        width: '100%',
        height: '100%',
        borderRadius: '12px',
      },
    },
    issue: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    title: {
      color: _var(CSS_VARIABLES.colorAdditionalWhite),
      textAlign: 'center',
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: '165%',
      letterSpacing: '0.4px',
      marginBottom: '34px',
      [theme.breakpoints.up(Layout.laptop)]: {
        color: '#fff',
      },
    },
    backButton: {
      position: 'absolute',
      left: '20px',
      top: '20px',
      [theme.breakpoints.up(Layout.laptop)]: {
        display: 'none',
      },
    },
    fieldName: {
      overflow: 'hidden',
      color: '#fff',
      fontFeatureSettings: "'clig' off, 'liga' off",
      textOverflow: 'ellipsis',
      wordBreak: 'keep-all',
      whiteSpace: 'nowrap',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '22px',
      letterSpacing: '0.3px',
      minWidth: 'fit-content',
    },
    fieldValue: {
      color: '#778',
      fontSize: '14px',
      fontWeight: 600,
      letterSpacing: '0.2px',
      maxWidth: '100%',
      textWrap: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      wordBreak: 'keep-all',
      marginLeft: '12px',
      [theme.breakpoints.up(Layout.laptop)]: {
        color: '#A4A8AB',
      },
    },
    section: {},
    menu: {},
    menuItem: {
      cursor: 'pointer',
      backgroundColor: '#21203B',
      height: '48px',
      width: '100%',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0px 16px',

      '& + &': {
        borderTop: '0.5px solid rgba(255, 255, 255, 0.10)',
      },
      '&:first-of-type': {
        borderRadius: '12px 12px 0px 0px',
      },
      '&:last-of-type': {
        borderRadius: '0px 0px 12px 12px',
      },
      '&:first-of-type&:last-of-type': {
        borderRadius: '12px 12px 12px 12px',
      },
      [theme.breakpoints.up(Layout.laptop)]: {
        background: '#412D68',
      },
    },
    flex: {
      display: 'flex',
    },
    info: {
      padding: '20px 16px',
    },
    infoField: {
      display: 'flex',
      color: '#778',
      fontSize: '13px',
      fontWeight: 500,
      lineHeight: '17px',
      '& + &': {
        marginTop: '6px',
      },
    },
    infoFieldName: {},
    infoFieldValue: {
      marginLeft: '5px',
    },
    transaction: {
      '& .MuiInputBase-inputMultiline': {
        height: 'calc(100vh - 535px) !important',
        [theme.breakpoints.up(Layout.laptop)]: {
          height: '250px !important',
        },
      },
    },
    nonTransaction: {
      '& .MuiInputBase-inputMultiline': {
        height: 'calc(100vh - 465px) !important',
        [theme.breakpoints.up(Layout.laptop)]: {
          height: '250px !important',
        },
      },
    },
    inputBlock: {
      '& + &': {
        marginTop: '16px',
      },
      '& .MuiOutlinedInput-input': {
        color: '#fff',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '150%',
        letterSpacing: '0.2px',
      },
      '& .MuiOutlinedInput-input::placeholder': {
        color: '#778',
        opacity: '1',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #3E3D5E !important',
      },
    },
    input: {},
    saveButtonBlock: {
      paddingTop: '20px',
      background: 'transparent',
    },
  });
}
