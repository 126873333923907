import React, { useMemo, useState } from 'react';

import { appObserver } from '@core/state-management/utils';
import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { DeleteAccountViewModel } from '@modules/new-private/settings/components/delete-account/delete-account.vm';
import { Loading } from '@shared/components/loading';
import { BackButton } from '@shared/components/new-design/back-button/back-button';
import { DrawerDialog } from '@shared/components/new-design/drawer-dialog';
import SubmitButton from '@shared/components/new-design/submit-button/submit-button';
import { useNavigate } from '@shared/components/router';
import { ROUTES } from '@shared/constants/routes';
import classNames from 'classnames';
import { useMutation, useQuery } from 'react-query';

import { styles } from './delete-account.styles';

export interface DeleteAccountProps extends AppWithStyles<typeof styles> {}

const DeleteAccountComponent: React.FC<DeleteAccountProps> = appObserver(({ classes }) => {
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false);

  const navigate = useNavigate();
  const $vm = useMemo(() => new DeleteAccountViewModel(), []);

  const navigateToSettings = () => {
    navigate(ROUTES.mobilePrivate.settings.root);
  };

  const canUserSendDeletionRequestQuery = useQuery(
    ['can-user-send-deletion-request-query'],
    () => $vm.canSendDeletionRequest(),
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
    },
  );

  const fetchUserInfoQuery = useQuery(['fetch-user-info-query'], () => $vm.fetchUserInfo(), {
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false,
  });

  const deletionRequestMutation = useMutation(() => $vm.sendDeletionRequest(), {
    onSuccess: () => {
      $vm.logout();
      navigate(ROUTES.public.deleteAccountResult);
    },
  });

  const handleDeleteAccount = () => {
    setIsOpenConfirmDialog(false);
    deletionRequestMutation.mutate();
  };

  if (fetchUserInfoQuery.isFetching || canUserSendDeletionRequestQuery.isFetching) {
    return (
      <div className={classes.loading}>
        <Loading size={70} />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.deleteAccountBlock}>
        <div className={classes.deleteAccount}>
          <div>
            <div className={classes.backButton}>
              <BackButton onClick={navigateToSettings} />
            </div>
            <div className={classes.title}>{t`Delete account`}</div>
            <div className={classes.content}>
              <div className={classes.description}>
                <p>
                  {t`Hi ${$vm.name}!`}
                  <br />
                  {t`Sorry to see you go. We've got great updates coming next month that you might like.`}
                </p>
                <p>{t`If you're set on deleting your profile, it's just four quick steps.`}</p>
              </div>
            </div>
            <div className={classes.section}>
              <ul className={classes.menu}>
                <li className={classes.menuItem}>
                  <div className={classes.fieldNumber}>1</div>
                  <div
                    className={classes.fieldText}
                  >{t`Clear all accounts (close orders, sell gold, withdraw fiat).`}</div>
                </li>
                <li className={classes.menuItem}>
                  <div className={classes.fieldNumber}>2</div>
                  <div className={classes.fieldText}>{t`Delete account`}</div>
                </li>
                <li className={classes.menuItem}>
                  <div className={classes.fieldNumber}>3</div>
                  <div className={classes.fieldText}>{t`Confirm deletion in pop up`}</div>
                </li>
                <li className={classes.menuItem}>
                  <div className={classes.fieldNumber}>4</div>
                  <div
                    className={classes.fieldText}
                  >{t`No further action needed; your profile will be deleted automatically.`}</div>
                </li>
              </ul>
            </div>
            <div className={classes.space}>
              {!$vm.canBeDeleted ? (
                <div
                  className={classNames(classes.error, classes.info)}
                >{t`Please empty your accounts to activate Deletion button`}</div>
              ) : (
                <></>
              )}
              {$vm.canBeDeleted ? (
                <div
                  className={classNames(classes.request, classes.info)}
                >{t`Do you really want to delete user account?`}</div>
              ) : (
                <></>
              )}
            </div>
            <div className={classes.saveButtonBlock}>
              <SubmitButton
                label={t`Delete account`}
                disabled={!$vm.canBeDeleted}
                isLoading={deletionRequestMutation.isLoading}
                onSubmit={() => setIsOpenConfirmDialog(true)}
              />
            </div>
          </div>
        </div>
      </div>
      <DrawerDialog
        isOpen={isOpenConfirmDialog}
        onClose={() => setIsOpenConfirmDialog(false)}
        height={50}
      >
        <div className={classes.viewBlock}>
          <div className={classes.titleDialog}>{t`Delete account`}</div>
          <div className={classes.descriptionDialog}>
            {t`Your account will be deleted permanently`}
          </div>
          <div className={classes.submitDialog}>
            <SubmitButton label={t`Delete account`} onSubmit={handleDeleteAccount} />
          </div>
        </div>
      </DrawerDialog>
    </div>
  );
});

export default appWithStyles(styles)(DeleteAccountComponent);
