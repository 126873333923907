import React, { useMemo } from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { HelpCenterViewModel } from '@modules/new-private/settings/components/help-center/help-center.vm';
import { Theme, useMediaQuery } from '@mui/material';
import { Loading } from '@shared/components/loading';
import { BackButton } from '@shared/components/new-design/back-button/back-button';
import { useNavigate } from '@shared/components/router';
import { ROUTES } from '@shared/constants/routes';
import { IssueType } from '@shared/enums/issue-type.enum';
import { Layout } from '@shared/utils/layout';
import classNames from 'classnames';
import { useQuery } from 'react-query';

import { styles } from './help-center.styles';

export interface HelpCenterProps extends AppWithStyles<typeof styles> {}

const HelpCenterComponent: React.FC<HelpCenterProps> = ({ classes }) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(Layout.tablet));
  const $vm = useMemo(() => new HelpCenterViewModel(), []);

  const fetchUserInfoQuery = useQuery(['fetch-user-info-query'], () => $vm.fetchUserInfo(), {
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false,
  });

  const navigateToSettings = () => {
    navigate(ROUTES.mobilePrivate.settings.root);
  };

  const navigateToIssue = (type: IssueType) => {
    isMobile
      ? navigate(ROUTES.mobilePrivate.settings.helpCenter.issue(type))
      : navigate(ROUTES.desktopPrivate.settings.helpCenter.issue(type));
  };

  if (fetchUserInfoQuery.isFetching) {
    return (
      <div className={classes.loading}>
        <Loading size={70} />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.securityBlock}>
        <div className={classes.security}>
          <div>
            <div className={classes.backButton}>
              <BackButton onClick={navigateToSettings} />
            </div>
            <div className={classes.title}>{t`Help center`}</div>
            <div className={classes.content}>
              <div
                className={classes.description}
              >{t`Hi ${$vm.name}, and welcome to the Help Center. Please select the issue you are facing, describe it, and send it to us. We will try to help you within 24 hours.`}</div>
              <div className={classes.section}>
                <ul className={classes.menu}>
                  <li
                    className={classNames(classes.menuItem, classes.withArrow)}
                    onClick={() => navigateToIssue(IssueType.ACCOUNT_ISSUE)}
                  >
                    <div className={classes.flex}>
                      <div className={classes.fieldIcon}></div>
                      <div className={classes.fieldName}>{t`Account issue`}</div>
                    </div>
                  </li>
                </ul>
              </div>
              <div
                className={classes.description}
              >{t`Select this option if you have questions about registration, account login, KYC, status, security, data history, and more.`}</div>
              <div className={classes.section}>
                <ul className={classes.menu}>
                  <li
                    className={classNames(classes.menuItem, classes.withArrow)}
                    onClick={() => navigateToIssue(IssueType.TRANSACTION_ISSUE)}
                  >
                    <div className={classes.flex}>
                      <div className={classes.fieldIcon}></div>
                      <div className={classes.fieldName}>{t`Transaction issue`}</div>
                    </div>
                  </li>
                </ul>
              </div>
              <div
                className={classes.description}
              >{t`Select this option if you have inquiries about a specific transaction. For a prompt response, please provide the transaction ID.`}</div>
              <div className={classes.section}>
                <ul className={classes.menu}>
                  <li
                    className={classNames(classes.menuItem, classes.withArrow)}
                    onClick={() => navigateToIssue(IssueType.GENERAL_QUESTION)}
                  >
                    <div className={classes.flex}>
                      <div className={classes.fieldIcon}></div>
                      <div className={classes.fieldName}>{t`General question`}</div>
                    </div>
                  </li>
                </ul>
              </div>
              <div
                className={classes.description}
              >{t`Select this option for inquiries about "How to" guides, functions, licensing, or any other questions unrelated to specific transactions or account status.`}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default appWithStyles(styles)(HelpCenterComponent);
