import React, { FC } from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { DesktopContetLayout } from '@modules/new-private/layout/desktop-content-layout';
import { ROUTES } from '@shared/constants/routes';

// eslint-disable-next-line import/namespace,import/default
import ChangePhone from '../change-phone/change-phone';

import { styles } from './change-phone-desktop-container.styles';

export interface ChangePhoneDesktopContainerProps extends AppWithStyles<typeof styles> {}

const ChangePhoneDesktopContainerComponent: FC<ChangePhoneDesktopContainerProps> = ({
  classes,
}) => {
  const routes = [
    { label: t`Settings`, path: ROUTES.desktopPrivate.settings.root },
    { label: t`Security`, path: ROUTES.desktopPrivate.settings.security.root },
    {
      label: t`Phone verification`,
      path: ROUTES.desktopPrivate.settings.security.changePassword,
      disabled: true,
    },
  ];

  return (
    <DesktopContetLayout links={routes}>
      <div className={classes.content}>
        <ChangePhone />
      </div>
    </DesktopContetLayout>
  );
};
export default appWithStyles(styles)(ChangePhoneDesktopContainerComponent);
