import { t } from '@lingui/macro';
import DesktopContainer from '@shared/components/new-design/containers/desktop-container/desktop-container';
import MobileContainer from '@shared/components/new-design/containers/mobile-container/mobile-container';
import { ROUTES } from '@shared/constants/routes';

import DeleteAccount from './delete-account';

const routes = [
  { label: t`Settings`, path: ROUTES.desktopPrivate.settings.root },
  { label: t`Delete account`, path: ROUTES.desktopPrivate.settings.deleteAccount, disabled: true },
];

export const DesktopDeleteAccount = () => (
  <DesktopContainer routes={routes}>
    <DeleteAccount />
  </DesktopContainer>
);

export const MobileDeleteAccount = () => (
  <MobileContainer>
    <DeleteAccount />
  </MobileContainer>
);
