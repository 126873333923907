import React, { ChangeEvent, useMemo, useState } from 'react';

import { appObserver } from '@core/state-management/utils';
import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { Theme, useMediaQuery } from '@mui/material';
import { Loading } from '@shared/components/loading';
import { BackButton } from '@shared/components/new-design/back-button/back-button';
import { useNavigate } from '@shared/components/router';
import { ROUTES } from '@shared/constants/routes';
import { Layout } from '@shared/utils/layout';
import classNames from 'classnames';
import { useMutation, useQuery } from 'react-query';

import { PersonalInfoViewModel } from './personal-info.vm';

import { PencilButton, StyledTextField, styles } from './personal-info.styles';

export interface PersonalInfoProps extends AppWithStyles<typeof styles> {}

const PersonalInfoComponent: React.FC<PersonalInfoProps> = appObserver(({ classes }) => {
  const $vm = useMemo(() => new PersonalInfoViewModel(), []);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(Layout.tablet));

  const [isAciveEditMode, setIsAciveEditMode] = useState(false);
  const [alterName, setAlterName] = useState<string>('');
  const fetchMyUserDetailsQuery = useQuery(['fetch-my-user-data'], () => $vm.fetchMyUserDetails(), {
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false,
    onSuccess: () => {
      setAlterName($vm.me?.asJson.alterName || '');
    },
  });

  const changeAlterNameMutation = useMutation(
    (data: { alterName: string }) => $vm.changeAlterName(data.alterName),
    {
      onSettled: () => {
        setIsAciveEditMode(false);
      },
    },
  );

  const handleSave = () => {
    if (!$vm.isValidAlterName(alterName)) return;
    changeAlterNameMutation.mutate({ alterName });
  };

  const onChangeAlterName = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setAlterName(e.target.value || '');
  };

  const navigate = useNavigate();

  const navigateToSettings = () => {
    isMobile
      ? navigate(ROUTES.mobilePrivate.settings.root)
      : navigate(ROUTES.desktopPrivate.settings.root);
  };

  const navigateToChangeAvatar = () => {
    isMobile
      ? navigate(ROUTES.mobilePrivate.settings.changeAvatar)
      : navigate(ROUTES.desktopPrivate.settings.changeAvatar);
  };

  let userIcon = <></>;

  if (fetchMyUserDetailsQuery.isFetching) {
    return (
      <div className={classes.root}>
        <Loading size={70} />
      </div>
    );
  }

  if ($vm.me?.asJson.avatarUrl) {
    userIcon = (
      <div className={classes.icon}>
        <div
          style={{
            width: '80px',
            height: '80px',
            borderRadius: '50%',
            overflow: 'hidden',
            backgroundColor: `${$vm.me?.asJson.systemColor}`,
          }}
        >
          <img className={classes.image} src={$vm.me?.asJson.avatarUrl} />
        </div>
        <div className={classes.changeAvatarButton} onClick={navigateToChangeAvatar}></div>
      </div>
    );
  } else {
    userIcon = (
      <div className={classes.icon}>
        <div
          style={{
            width: '80px',
            height: '80px',
            borderRadius: '50%',
            backgroundColor: `${$vm.me?.asJson.systemColor}`,
          }}
        >
          <div className={classes.initials}>{$vm.me?.asJson.initials}</div>
        </div>
        <div className={classes.changeAvatarButton} onClick={navigateToChangeAvatar}></div>
      </div>
    );
  }

  const saveButton = changeAlterNameMutation.isLoading ? (
    <Loading size={30} />
  ) : (
    <div
      className={classNames(classes.saveButton, {
        [classes.active]: $vm.isValidAlterName(alterName),
        [classes.disabled]: !$vm.isValidAlterName(alterName),
      })}
      onClick={handleSave}
    >{t`Save`}</div>
  );

  return (
    <div className={classes.root}>
      <div className={classes.personalInfoBlock}>
        <div className={classes.personalInfo}>
          <div>
            <div className={classes.backButton}>
              <BackButton onClick={navigateToSettings} />
            </div>
            <div className={classes.title}>{t`Personal information`}</div>
            <div className={classes.userIcon}>{userIcon}</div>
            <div className={classes.section}>
              <ul className={classes.menu}>
                <li className={classNames(classes.menuItem, classes.editable)}>
                  <div className={classes.fieldName}>{t`Nick Name`}</div>
                  {isAciveEditMode ? (
                    <StyledTextField
                      type={'outlined'}
                      autoFocus={true}
                      onChange={onChangeAlterName}
                    />
                  ) : (
                    <></>
                  )}
                  {isAciveEditMode ? (
                    saveButton
                  ) : (
                    <div className={classes.flex}>
                      <div className={classes.fieldValue}>{$vm.me?.asJson.alterName}</div>
                      <PencilButton onClick={() => setIsAciveEditMode(true)} />
                    </div>
                  )}
                </li>
                <li className={classes.menuItem}>
                  <div className={classes.fieldName}>{t`Real Name`}</div>
                  <div className={classes.fieldValue}>{$vm.me?.asJson.name}</div>
                </li>
                <li className={classes.menuItem}>
                  <div className={classes.fieldName}>{t`Phone #`}</div>
                  <div className={classes.fieldValue}>{$vm.me?.asJson.phone}</div>
                </li>
                <li className={classes.menuItem}>
                  <div className={classes.fieldName}>{t`Email`}</div>
                  <div className={classes.fieldValue}>{$vm.me?.asJson.email}</div>
                </li>
                <li className={classes.menuItem}>
                  <div className={classes.fieldName}>{t`Location`}</div>
                  <div className={classes.fieldValue}>{$vm.me?.asJson.citizenshipCountry}</div>
                </li>
                <li className={classNames(classes.menuItem, classes.address)}>
                  <div className={classes.fieldName}>{t`Address`}</div>
                  <div className={classes.fieldValue}>{$vm.me?.asJson.address}</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default appWithStyles(styles)(PersonalInfoComponent);
