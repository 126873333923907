import { appCreateStyles } from '@core/theme/utils/create-styles';
import { Theme } from '@mui/material';
import { _var, CSS_VARIABLES, Layout } from '@shared/utils/layout';

export function styles(theme: Theme) {
  return appCreateStyles({
    root: {
      width: '100%',
      height: '100%',
      [theme.breakpoints.up(Layout.laptop)]: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
      },
    },
    loading: {
      width: '100%',
      height: '100%',
      [theme.breakpoints.up(Layout.laptop)]: {
        width: '100%',
        height: '100%',
        minHeight: '30vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    deleteAccountBlock: {
      [theme.breakpoints.up(Layout.laptop)]: {
        borderRadius: '12px',
        width: '100%',
        background: 'transparent',
      },
      [theme.breakpoints.down(Layout.tablet)]: {
        width: '100%',
        height: '100%',
        borderRadius: '12px',
      },
    },
    deleteAccount: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    title: {
      color: _var(CSS_VARIABLES.colorAdditionalWhite),
      textAlign: 'center',
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: '165%',
      letterSpacing: '0.4px',
      [theme.breakpoints.up(Layout.laptop)]: {
        color: '#fff',
      },
    },
    backButton: {
      position: 'absolute',
      left: '20px',
      top: '20px',
      [theme.breakpoints.up(Layout.laptop)]: {
        display: 'none',
      },
    },
    content: {
      marginTop: '30px',
    },
    description: {
      color: '#778',
      fontSize: '13px',
      fontWeight: 500,
      lineHeight: '17px',
      letterSpacing: '0.2px',
      padding: '10px',
      marginBottom: '20px',
    },
    menu: {
      marginTop: '16px',
    },
    menuItem: {
      backgroundColor: '#21203B',
      height: '66px',
      width: '100%',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
      padding: '0px 16px',

      '& + &': {
        borderTop: '0.5px solid rgba(255, 255, 255, 0.10)',
      },
      '&:first-of-type': {
        borderRadius: '12px 12px 0px 0px',
      },
      '&:last-of-type': {
        borderRadius: '0px 0px 12px 12px',
      },
      [theme.breakpoints.up(Layout.laptop)]: {
        background: '#412D68',
      },
    },
    fieldNumber: {
      overflow: 'hidden',
      color: '#778',
      textOverflow: 'ellipsis',
      fontSize: '30px',
      fontWeight: 500,
      minWidth: '30px',
      marginRight: '15px',
      display: 'flex',
      justifyContent: 'center',
    },
    fieldText: {
      color: '#778',
      fontSize: '13px',
      fontWeight: 500,
      maxWidth: '100%',
      textWrap: 'wrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineHeight: '17px',
      [theme.breakpoints.up(Layout.laptop)]: {
        color: '#A4A8AB',
      },
    },
    section: {
      marginTop: '20px',
      '& ul': {
        paddingLeft: '0px',
      },
    },
    space: {
      width: '100%',
      position: 'relative',
      height: 'calc(100vh - 617px)',
      [theme.breakpoints.up(Layout.laptop)]: {
        height: '40px',
      },
    },
    info: {
      fontSize: '13px',
      fontWeight: 500,
      lineHeight: '17px',
      position: 'absolute',
      bottom: '10px',
      left: '10px',
    },
    error: {
      color: '#F00',
    },
    request: {
      color: '#FFF',
    },
    saveButtonBlock: {
      paddingTop: '20px',
      background: 'transparent',
    },
    viewBlock: {
      padding: '20px 20px 20px 20px',
      color: '#fff',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    titleDialog: {
      color: _var(CSS_VARIABLES.colorPrimary100),
      textAlign: 'center',
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: '165%',
      letterSpacing: '0.4px',
      marginTop: '13px',
    },
    descriptionDialog: {
      color: 'rgba(255, 255, 255, 0.60)',
      textAlign: 'center',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '170%',
      letterSpacing: '0.3px',
      width: '315px',
      marginTop: '24px',
    },
    submitDialog: {
      position: 'absolute',
      bottom: '20px',
      width: 'calc(100% - 40px)',
    },
  });
}
