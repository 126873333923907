import { appInject } from '@core/di/utils';
import { DI_TOKENS } from '@shared/constants/di';
import { IAuthService } from '@shared/types/auth-service';
import { INotificationService } from '@shared/types/notification-service';

export class ChangePasswordViewModel {
  private authService = appInject<IAuthService>(DI_TOKENS.authService);
  private notificationsService = appInject<INotificationService>(DI_TOKENS.notificationService);

  constructor() {}

  changePassword = async (oldPassword: string, password: string) => {
    return await this.authService.changePassword(oldPassword, password);
  };

  showMessage(text: string) {
    return this.notificationsService.showSuccess(text);
  }
}
