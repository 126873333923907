import React from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';

import PersonalInfo from '../personal-info/personal-info';

import { styles } from './personal-info-mobile-container.styles';

export interface MobileSettingsProps extends AppWithStyles<typeof styles> {}

const MobileSettings: React.FC<MobileSettingsProps> = ({ classes }) => {
  return (
    <div className={classes.root}>
      <PersonalInfo />
    </div>
  );
};

export default appWithStyles(styles)(MobileSettings);
