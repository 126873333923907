import { appInject } from '@core/di/utils';
import { appMakeObservable, appObservable } from '@core/state-management/utils';
import { DI_TOKENS } from '@shared/constants/di';
import { AuthGetModel } from '@shared/models/auth/get-model';
import { ProductType } from '@shared/models/products/product-model';
import { UserDetailsModel } from '@shared/models/users/details-model';
import { BlockchainWalletModel } from '@shared/models/wallets/blockchain-wallet';
import { WalletsListModel } from '@shared/models/wallets/list-model';
import { IAuthService } from '@shared/types/auth-service';
import { IConfigService } from '@shared/types/config-service';
import { ILocalesVM } from '@shared/types/locales-vm';
import { INotificationService } from '@shared/types/notification-service';
import { IProductsVM } from '@shared/types/products-vm';
import { IRatesVM } from '@shared/types/rates-vm';
import { IUsersService } from '@shared/types/users-service';
import { IWalletsService } from '@shared/types/wallets-service';

export class MobileSettingsViewModel {
  private usersService = appInject<IUsersService>(DI_TOKENS.usersService);
  private walletsService = appInject<IWalletsService>(DI_TOKENS.walletsService);
  private ratesVM = appInject<IRatesVM>(DI_TOKENS.ratesVM);
  private productsVM = appInject<IProductsVM>(DI_TOKENS.productsVM);
  private localesVM = appInject<ILocalesVM>(DI_TOKENS.localesVM);
  private authService = appInject<IAuthService>(DI_TOKENS.authService);
  private configService = appInject<IConfigService>(DI_TOKENS.configService);
  private notificationsService = appInject<INotificationService>(DI_TOKENS.notificationService);
  private _userInfo: AuthGetModel | null = null;
  private _me = {
    data: null as UserDetailsModel | null,
  };

  private _wallets = {
    data: [] as Array<WalletsListModel>,
  };
  private _blockchainWallet: BlockchainWalletModel | null = null;

  get me() {
    return this._me.data;
  }

  get version() {
    return this.configService.version;
  }

  get wallets() {
    return this._wallets.data;
  }

  get myBlockchainWallet() {
    return this._blockchainWallet;
  }

  get locale() {
    return this.localesVM.locale;
  }

  get isUserPhoneVerified(): boolean {
    return Boolean(this._userInfo?.isPhoneVerified && this._userInfo.phone);
  }

  constructor() {
    appMakeObservable(this, {
      _me: appObservable,
      _wallets: appObservable,
    });
  }

  async fetchMyUserDetails() {
    this._me.data = await this.usersService.getMyUserDetails();
  }

  fetchWallets = async () => {
    this._wallets.data = await this.walletsService.getList(this.ratesVM.currency, false);
  };

  fetchMyBlockchainWallet = async () => {
    this._blockchainWallet = await this.walletsService.getBlockchainWallets();
  };

  getSmartCoinWallet() {
    return this._wallets.data.find(
      (w) => this.productsVM.getProductTypeByCurrency(w.asJson.currency) === ProductType.SMART_COIN,
    );
  }

  fetchUserInfo = async () => {
    this._userInfo = await this.authService.getUserInfo(false);
  };

  getActiveCurrency() {
    return this.ratesVM.currency;
  }

  get blockchainWalletLink() {
    if (!this._blockchainWallet) return '';
    return `${this.configService.blockchainViewerOrigin}/transactions?search=${this._blockchainWallet.publicKey}`;
  }

  handleLogout = () => {
    this.authService.signOut();
  };

  showMessage(text: string) {
    return this.notificationsService.showSuccess(text);
  }
}
