import { appInject } from '@core/di/utils';
import { appMakeObservable, appObservable } from '@core/state-management/utils';
import { DI_TOKENS } from '@shared/constants/di';
import { UserDetailsModel } from '@shared/models/users/details-model';
import { TermsDocumentModel } from '@shared/models/users/terms-documents-model';
import { IUsersService } from '@shared/types/users-service';

export class MobileDocumentsViewModel {
  private usersService = appInject<IUsersService>(DI_TOKENS.usersService);

  private _me = {
    data: null as UserDetailsModel | null,
  };

  private _termsDocuments = {
    data: [] as Array<TermsDocumentModel>,
  };

  private _acceptedDocuments = {
    data: new Map(),
  };

  get me() {
    return this._me.data;
  }

  get termsDocuments() {
    return this._termsDocuments.data;
  }

  get acceptedDocuments() {
    return this._acceptedDocuments.data;
  }

  get approvedDocumentIds() {
    return this._me.data?.asJson.approvedDocumentIds;
  }

  constructor() {
    appMakeObservable(this, {
      _me: appObservable,
      _termsDocuments: appObservable,
      _acceptedDocuments: appObservable,
    });
  }

  async fetchMyUserDetails() {
    this._me.data = await this.usersService.getMyUserDetails();
  }

  async fetchTermsDocuments() {
    this._termsDocuments.data = await this.usersService.getTermsDocuments();
  }

  refreshAccepteDocuments() {
    this._acceptedDocuments.data = new Map();
    this.termsDocuments.forEach((doc) => {
      const isChecked = this.approvedDocumentIds?.includes(doc.id) || false;
      this.acceptedDocuments.set(doc.id, isChecked);
    });
  }
}
